import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import { setValuesSessionStorage } from "../../../../util/Session";

export const DropdownBrand = (props: any) => {
    const {
        brandTitle,
        setBrandTitle,
        brandTitles,
        brandTitlesStorage,
        setClearForm,
        checkVisibleForm,
    } = props;

    const brandItemsArray = brandTitles ? brandTitles.map((brand: any) => brand.name) : [];
    const brandItems = brandItemsArray[0]
        ? brandItemsArray.sort()
        : brandTitlesStorage;
    const [filteredBrandItems, setFilteredBrandItems] = useState([...brandItems]);
    const [inputBrandValue, setInputBrandValue] = useState('');

    const handleChange = (value: any) => {
        setBrandTitle(value);
        setInputBrandValue(value);
        setClearForm(false);
        checkVisibleForm(0, value);
    };
    const filterTitles = (value: any) => {
        setInputBrandValue(value);
        setFilteredBrandItems(brandItems.filter((str: any) => str.toLowerCase().indexOf(value.toLowerCase()) >= 0));
    };

    if (typeof window !== 'undefined') {
        setValuesSessionStorage("brandItems", brandItems);
        setValuesSessionStorage("brandTitle", brandTitle);
    }

    return (
        <Form.Group controlId="dropdownBrand" className="col-md-6 col-lg-3 p-1">
            <Dropdown
                className="p-0 bg-white border-form-input"
                onClick={() => {
                    setInputBrandValue('')
                    filterTitles('');
                }}
                onSelect={(event) => {
                    handleChange(event);
                    setValuesSessionStorage("modelTitle", 'Model...');
                }}
            >
                <Dropdown.Toggle
                    id="dropdown-basic"
                    as="div"
                    className="w-100 bg-white"
                    disabled
                >
                    <input
                        type="text"
                        placeholder={brandTitle}
                        className="bg-white w-100 text-secondary pl-3"
                        value={inputBrandValue}
                        onChange={(event) => filterTitles(event.currentTarget.value)}
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu className="col-md-3 m-0 p-0">
                    {
                        <Dropdown.Item
                            key={'Merk...'}
                            eventKey={'Merk...'}
                            active={'Merk...' === brandTitle}
                        >{'Merk...'}
                        </Dropdown.Item>
                    }
                    {
                        filteredBrandItems.map(
                            (title, index) => <Dropdown.Item
                                key={index}
                                eventKey={title}
                                active={title === brandTitle}
                            >{
                                    title}
                            </Dropdown.Item>
                        )
                    }
                </Dropdown.Menu>
            </Dropdown>
        </Form.Group>
    )
};
