import React from 'react';
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {CylinderCapacityContainer} from "./addition/CylinderCapacityContainer";
import {DescriptionContainer} from "./addition/DescriptionContainer";
import {CylinderAmountContainer} from "./addition/CylinderAmountContainer";
import {RadiusContainer} from "./addition/RadiusContainer";
import {DaysContainer} from "./addition/DaysContainer";
import {CarDealerContainer} from "./addition/CarDealerContainer";
import {PowerContainer} from "./addition/PowerContainer";
import {SeatsContainer} from "./addition/SeatsContainer";
// import {WeightContainer} from "./addition/WeightContainer";
import {CheckboxesContainer} from "./addition/CheckboxesContainer";
import {QueryDto} from "../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../apis";

export const FormListAddition = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;
    const {
        getDropDownItems,
        addDropdownMax,
        addDropdownMin,
        countCheckboxDropdownMinMax,
        countInputs,
        visibleAdditionForm,
        resetFormList,
        clearForm,
        setClearForm,
        inputTextValue,
        setInputTextValue,
        visibleForm,
        checkVisibleForm
    } = props;

    return (
        <>
            <Row className="m-0 p-0">
                <DescriptionContainer
                    queryDto={queryDto}
                    saveQuery={saveQuery}
                    countInputs={countInputs}
                    clearForm={clearForm}
                    setClearForm={setClearForm}
                    inputTextValue={inputTextValue}
                    setInputTextValue={setInputTextValue}
                    checkVisibleForm={checkVisibleForm}
                />
                <CylinderCapacityContainer
                    queryDto={queryDto}
                    saveQuery={saveQuery}
                    addDropdownMax={addDropdownMax}
                    addDropdownMin={addDropdownMin}
                    countCheckboxDropdownMinMax={countCheckboxDropdownMinMax}
                    clearForm={clearForm}
                    setClearForm={setClearForm}
                    checkVisibleForm={checkVisibleForm}
                />
                <CylinderAmountContainer
                    queryDto={queryDto}
                    saveQuery={saveQuery}
                    addDropdownMax={addDropdownMax}
                    addDropdownMin={addDropdownMin}
                    countCheckboxDropdownMinMax={countCheckboxDropdownMinMax}
                    clearForm={clearForm}
                    setClearForm={setClearForm}
                    checkVisibleForm={checkVisibleForm}

                />
                <RadiusContainer
                    queryDto={queryDto}
                    saveQuery={saveQuery}
                    countInputs={countInputs}
                    getDropDownItems={getDropDownItems}
                    clearForm={clearForm}
                    setClearForm={setClearForm}
                    inputTextValue={inputTextValue}
                    setInputTextValue={setInputTextValue}
                />
            </Row>
            <Row className="m-0 p-0">
                <DaysContainer
                    queryDto={queryDto}
                    saveQuery={saveQuery}
                    getDropDownItems={getDropDownItems}
                    countInputs={countInputs}
                    clearForm={clearForm}
                    setClearForm={setClearForm}
                    checkVisibleForm={checkVisibleForm}
                />
                <CarDealerContainer
                    queryDto={queryDto}
                    saveQuery={saveQuery}
                    getDropDownItems={getDropDownItems}
                    countInputs={countInputs}
                    clearForm={clearForm}
                    setClearForm={setClearForm}
                    checkVisibleForm={checkVisibleForm}
                />
                <PowerContainer
                    queryDto={queryDto}
                    saveQuery={saveQuery}
                    addDropdownMax={addDropdownMax}
                    addDropdownMin={addDropdownMin}
                    countCheckboxDropdownMinMax={countCheckboxDropdownMinMax}
                    clearForm={clearForm}
                    setClearForm={setClearForm}
                    checkVisibleForm={checkVisibleForm}
                />
                <SeatsContainer
                    queryDto={queryDto}
                    saveQuery={saveQuery}
                    addDropdownMax={addDropdownMax}
                    addDropdownMin={addDropdownMin}
                    countCheckboxDropdownMinMax={countCheckboxDropdownMinMax}
                    clearForm={clearForm}
                    setClearForm={setClearForm}
                    checkVisibleForm={checkVisibleForm}
                />

            </Row>
           {/* <Row className="m-0 p-0">
                <WeightContainer
                    queryDto={queryDto}
                    saveQuery={saveQuery}
                    addDropdownMax={addDropdownMax}
                    addDropdownMin={addDropdownMin}
                    countCheckboxDropdownMinMax={countCheckboxDropdownMinMax}
                    clearForm={clearForm}
                    setClearForm={setClearForm}
                    checkVisibleForm={checkVisibleForm}
                />
            </Row>*/}
            <CheckboxesContainer
                queryDto={queryDto}
                saveQuery={saveQuery}
                setClearForm={setClearForm}
                clearForm={clearForm}
            />
            <Row className="m-0 p-0">
                <Button
                    className={`${visibleForm && !visibleAdditionForm ? '' : 'clean-hidden'} clean-btn col-12 col-md-2 ml-auto mr-1 font-weight-bold rounded-0 `}
                    onClick={() => resetFormList()}
                >
                    WIS
                </Button>
                <Button className="ml-10 col-12 col-md-3 btn-success button-form-homepage font-weight-bold rounded-0"
                        type="submit"
                >
                    Zoeken
                </Button>
            </Row>
        </>
    )
};
