import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { setValuesSessionStorage } from "../../../../util/Session";

export const DropdownModel = (props: any) => {
    const {
        filteredModelItems,
        setFilteredModelItems,
        modelTitle,
        setModelTitle,
        inputModelValue,
        setInputModelValue,
        modelItems,
        setClearForm,
        checkVisibleForm,
    } = props;

    const handleChange = (value: any) => {
        setInputModelValue(value);
        setModelTitle(value);
        setClearForm(false);
        checkVisibleForm(0, value);
    };

    const filterTitles = (value: any) => {
        setInputModelValue(value);
        setFilteredModelItems(modelItems.filter((str: string) => str.toLowerCase().indexOf(value.toLowerCase()) >= 0));
    };

    // if (typeof window !== 'undefined') {
    //     modelItems[0] ? setValuesSessionStorage("modelItems", modelItems) : null;
    //     setValuesSessionStorage("modelTitle", modelTitle)
    // }

    return (
        <Dropdown
            className="bg-white p-0 border-form-input"
            onClick={() => {
                setInputModelValue('');
                filterTitles('');
            }}
            onSelect={(eventKey) => {
                handleChange(eventKey)
                setValuesSessionStorage("modelTitle", eventKey);
                setInputModelValue('');
            }}
        >
            <Dropdown.Toggle
                id="dropdown-basic"
                as="div"
                className="w-100"
            >
                <input
                    type="text"
                    placeholder={modelTitle}
                    className="w-100 text-secondary pl-3"
                    value={inputModelValue}
                    onChange={(event) => filterTitles(event.currentTarget.value)}
                />
            </Dropdown.Toggle>
            <Dropdown.Menu className="col-md-3 m-0 p-0">
                {
                    <Dropdown.Item
                        key={'Model...'}
                        eventKey={'Model...'}
                        active={'Model...' === modelTitle}
                    >{'Model...'}
                    </Dropdown.Item>
                }
                {
                    filteredModelItems.map(
                        (title: any, index: number) => <Dropdown.Item
                            key={index}
                            eventKey={title}
                            active={title === modelTitle}
                        >
                            {title}
                        </Dropdown.Item>
                    )
                }
            </Dropdown.Menu>
        </Dropdown>
    )
};


