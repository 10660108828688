import React, { useState } from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DropdownMinMax } from '../dropdowns/DropdownMinMax';
import { itemsCylinderCapacity } from "../../../../Constants";
import {QueryDto} from "../../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../../apis";

export const CylinderCapacityContainer = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;
    const { addDropdownMax, addDropdownMin, countCheckboxDropdownMinMax, clearForm, setClearForm, checkVisibleForm } = props;

    const [cylinderCapacityMin, _setCylinderCapacityMin] = useState(queryDto.cylinder_capacity_min/*getSessionStorageProperty('cylinderCapacityMin')*/ || 'Min.');
    const [cylinderCapacityMax, _setCylinderCapacityMax] = useState(queryDto.cylinder_capacity_max/*getSessionStorageProperty('cylinderCapacityMax')*/ || 'Max.');

    const setCylinderCapacityMin = (value: any) => {
        queryDto.cylinder_capacity_min=value;
        saveQuery('cylinder_capacity_min', value);
        _setCylinderCapacityMin(value);
    }
    const setCylinderCapacityMax = (value: any) => {
        queryDto.cylinder_capacity_max=value;
        saveQuery('cylinder_capacity_max', value);
        _setCylinderCapacityMax(value);
    }

    if ((clearForm || queryDto.cylinder_capacity_min===undefined) && cylinderCapacityMin !== 'Min.') {
        _setCylinderCapacityMin('Min.');
    }

    if ((clearForm || queryDto.cylinder_capacity_max===undefined) && cylinderCapacityMax !== 'Max.') {
        _setCylinderCapacityMax('Max.');
    }
    return (
        <Form.Group
            className="col-12 col-md-6 col-lg-3 p-1"
            controlId="cylinder-capacity"
        >
            <Form.Label className="font-weight-bold">
                Cilinderinhoud
                    </Form.Label>
            <Row className="m-0">
                <DropdownMinMax
                    id={'cylinderCapacityMin'}
                    idCompare={'cylinderCapacityMax'}
                  /*  conditionShowItems={(index: any) => cylinderCapacityItems[index]}*/
                    arrayForItems={itemsCylinderCapacity}
                    addDropdown={addDropdownMin}
                    setTitle={setCylinderCapacityMin}
                    title={cylinderCapacityMin}
                    titleCompare={cylinderCapacityMax}
                    setTitleCompare={setCylinderCapacityMax}
                    classProperties={"dropdown-min-max col-6 p-0 m-0"}
                    countCheckboxDropdownMinMax={countCheckboxDropdownMinMax}
                    initialValue={'min'}
                    isDivide={true}
                    setClearForm={setClearForm}
                    checkVisibleForm={checkVisibleForm}
                />
                <DropdownMinMax
                    id={'cylinderCapacityMax'}
                    idCompare={'cylinderCapacityMin'}
                  /*  conditionShowItems={(index: any) => cylinderCapacityItems[index]}*/
                    arrayForItems={itemsCylinderCapacity}
                    addDropdown={addDropdownMax}
                    setTitle={setCylinderCapacityMax}
                    title={cylinderCapacityMax}
                    titleCompare={cylinderCapacityMin}
                    setTitleCompare={setCylinderCapacityMin}
                    classProperties={"dropdown-min-max col-6 p-0 m-0"}
                    countCheckboxDropdownMinMax={countCheckboxDropdownMinMax}
                    initialValue={'max'}
                    isDivide={true}
                    setClearForm={setClearForm}
                    checkVisibleForm={checkVisibleForm}
                />
            </Row>
        </Form.Group>
    )
}