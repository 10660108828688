import React, {useEffect, useState} from "react";

interface QueryRendererProps<E> {
    query: any
    fetchResponse: () => Promise<E>
    renderOnOK: (data: E) => JSX.Element
    renderOnStart?: JSX.Element
    renderOnError?: JSX.Element
}


export const QueryRenderer = (props: QueryRendererProps<any>): JSX.Element => {
    const [data, setData] = useState({isFetching: false, error: false});
    const [result, setResult] = useState(null);
    const [lastQuery, setLastQuery] = useState(null);
    const fetchResponse = props.fetchResponse;
    const query = props.query;
    useEffect(() => {
        const fetchData = async () => {
            if (lastQuery !== query) {
                try {
                    setLastQuery(query);
                    setData({isFetching: true, error: false});
                    const response = await fetchResponse();
                    setResult(response);
                    setData({isFetching: false, error: false});

                } catch (e) {
                    console.log(e);
                    setResult(null);
                    setData({isFetching: false, error: true});
                }
            }
        };
        fetchData();
    }, [lastQuery, query, fetchResponse]);

    return <>
        {data.error && props.renderOnError != null && props.renderOnError}
        {!data.error && result == null && props.renderOnStart != null && props.renderOnStart}
        {result != null && props.renderOnOK(result)}</>;
}