import React, {useEffect, useState} from 'react';
import {Navigation} from "../../layout/Navigation";
import {FormContact} from "./FormContact";
import {fetchContentForPage} from "../../util/RestQueries";

export const ContactPage = () => {
    const titles = ['Rijzuinig', 'Veelgestelde vragen'];

    const [html, setHtml] = useState("");

    useEffect(() => {
        const fetchData = async () =>
            setHtml(await fetchContentForPage("contact"))
        fetchData();
    }, []);
    return (
        <>
            <Navigation titles={titles}/>
            <div className="container-fluid contact">
                <span dangerouslySetInnerHTML={{__html: html}}/>
                <FormContact/>
            </div>
        </>
    );
};

export default ContactPage;
