import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { setValuesSessionStorage } from "../../../../util/Session";

export const DropdownMinMax = props => {
    const {
        // setAmountAdditionalFields,
        countCheckboxDropdownMinMax,
        // conditionShowItems,
        classProperties,
        arrayForItems,
        initialValue,
        addDropdown,
        setTitle,
        titleCompare,
        setTitleCompare,
        idCompare,
        title,
        id,
        isDivide,
        setClearForm,
        checkVisibleForm,
    } = props;

    const isMinMax = titleDropdown => (titleDropdown === 'Min.') || (titleDropdown === 'Max.');

    const setValue = (titleCurrent, titleCompare) => {
        setTitle(titleCurrent);
        setTitleCompare(titleCompare)
    };

    const setTitleSessionStorage = (titleCurrent, titleCompare) => {
        setValuesSessionStorage(idCompare, titleCompare);
        setValuesSessionStorage(id, titleCurrent);
    };

    const compareValue = value => {

        const isMinMaxAny = !isMinMax(value) && !isMinMax(titleCompare);
        const isValueMore = value > titleCompare;

        if (
            (isMinMaxAny && isValueMore && initialValue === 'min')
            || (isMinMaxAny && !isValueMore && initialValue === 'max')
        ) {
            setValue(titleCompare, value);
            setTitleSessionStorage(titleCompare, value);
            return;
        }

        if (
            (isMinMaxAny && isValueMore && initialValue === 'max')
            || (isMinMaxAny && !isValueMore && initialValue === 'min')
        ) {
            setValue(value, titleCompare);
            setTitleSessionStorage(value, titleCompare);
            return;
        }

        setTitle(value);
        setValuesSessionStorage(id, value);
        setClearForm(false);
        checkVisibleForm(0, value);
    }

    const showItemWithDot = value => {
        if (!isMinMax(value) && !isDivide) {
            return value;
        }
        const howManyThousands = Math.floor(value / 1000);

        if (howManyThousands) {
            const valueArray = ('' + value).split('');
            valueArray.splice(-3, 0, '.');
            return valueArray.join('');
        }
        return value;
    }

    return (
        <Dropdown className={classProperties}>
            <Dropdown.Toggle variant="light" id={"dropdown-" + id} className="shadow-none bg-white text-secondary" data-value={title}>
                {showItemWithDot(title)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    addDropdown(arrayForItems)
                        .map(
                            (item, index) => {
                                if (index !== 0) {
                                    return (
                                        <Dropdown.Item
                                            key={index}
                                            onClick={() => {
                                                compareValue(item)
                                                countCheckboxDropdownMinMax(id, item)
                                            }
                                            }
                                            active={+title === item}
                                        >
                                            {showItemWithDot(item)}
                                        </Dropdown.Item>
                                    )
                                }
                                return <Dropdown.Item
                                    key={index}
                                    onClick={() => {
                                        compareValue(item)
                                        countCheckboxDropdownMinMax(id, item)
                                    }}
                                    active={+title === item}
                                >
                                    {item}
                                </Dropdown.Item>

                            }
                        )
                }
            </Dropdown.Menu>
        </Dropdown>
    )
};
