import React from 'react';
import '../../styles/main.scss';
import facebook_logo from '../../images/facebook_logo.png';
import youtube_logo from '../../images/youtube_logo.png';
import Nav from 'react-bootstrap/Nav';

const popular_marks = [
  'Alfa Romeo',
  'Audi',
  'BMW',
  'Citroën',
  'Daihatsu',
  'Jeep',
  'Lexus',
  'Mazda',
  'Mitsubishi',
  'Peugeot',
];

export const Footer = (props:any) => {


  const titlesCarItems = popular_marks.map((title, index) => (
    <li key={index} onClick={() => props.handleClick(title)}>
      <Nav.Link className="nav-link text-dark" href="#">
        {title}
      </Nav.Link>
    </li>
  ));

  return (
    <div className="border-top border-secondary">
      <div className="container-fluid footer pl-3 pr-3 pl-lg-0 pr-lg-0">
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4">
            <h5 className="mb-4 font-weight-bold">Populaire merken</h5>
            <div className="row">
              <Nav className="nav flex-column col pl-3">
                <ul className="navbar-nav">{titlesCarItems.slice(0, 5)}</ul>
              </Nav>
              <Nav className="nav flex-column col">
                <ul className="navbar-nav">{titlesCarItems.slice(6)}</ul>
              </Nav>
            </div>
          </div>
          <div className="col-6 col-sm-3 col-md-3 col-lg-2 mb-4">
            <h5 className="mb-4 font-weight-bold">Sitemap</h5>
            <Nav className="nav flex-column">
              <ul className="navbar-nav">
                <li>
                  <Nav.Link className="nav-link text-dark" href="/home">
                    Home
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link className="nav-link text-dark" href="/faq">
                    FAQ
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link className="nav-link text-dark" href="/contact">
                    Contact
                  </Nav.Link>
                </li>
              </ul>
            </Nav>
          </div>
          <div className="col-6 col-sm-3 col-md-2 col-lg-2 mb-4">
            <h5 className="mb-4 font-weight-bold">Energielabel</h5>
            <div className="flex-column pt-3">
              <div className="text-right text-white arrow footer-letter-A">A</div>
              <div className="text-right text-white arrow footer-letter-B">B</div>
              <div className="text-right text-white arrow footer-letter-C">C</div>
              <div className="text-right text-white arrow footer-letter-D">D</div>
              <div className="text-right text-white arrow footer-letter-E">E</div>
            </div>
          </div>
          <div className="d-none d-md-block col-md-12 col-lg-4 mb-4">
            <h5 className="mb-4 font-weight-bold">Contact gegevens</h5>
            <Nav className="nav flex-column">
              <ul className="navbar-nav">
                <li className="pb-2 pt-2">Rijzuinig.nl B.V.</li>
                <li className="pb-2 pt-2">info@rijzuinig.nl</li>
              </ul>
            </Nav>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-sm-6 col-md-12 col-lg-12 align-self-center">
            <small className="sm-margin">&copy; Copyright Rijzuinig.nl</small>
            {/* <small className="sm-margin">
                            <a href="#" target="_blank">Algemene voorwaarden</a>
                        </small>
                        <small className="sm-margin">
                            <a href="#" target="_blank">Privacy beleid</a>
                        </small> */}
          </div>
          <div className="col-6 d-md-none d-sm-none text-right">
            <img src={facebook_logo} className="mr-3" alt="facebook-logo" />
            <img src={youtube_logo} alt="youtube-logo" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
