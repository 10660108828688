import React from "react";
import {popularModels} from "../../Constants";
// import parse from 'html-react-parser';

export const SeoText = (props: any) => {
    const {
        setLoadData,
        currentBrand,
        modelItems,
        // dataQuery,
        seoTexts,
    } = props;


    return (
        <>
            {
                seoTexts
                    ? <section className="pt-3 pb-3 pl-lg-0 pr-lg-0 col-12 row m-0">
                        {/*  {parse("" + seoTexts)}*/}
                        <div dangerouslySetInnerHTML={{__html: seoTexts}}/>
                    </section>
                    : null
            }
            <section className="border-top border-secondary pt-3 pb-3 pl-lg-0 pr-lg-0 col-12 row m-0">
                <div className="col-12 pb-3 p-0"><h2>Populaire occasions</h2></div>
                {
                    popularModels.map(
                        (item, index) => <div
                            key={index}
                            onClick={() => setLoadData({brand: item.brand, model: item.model})}
                            className="col-6 col-md-3 m-0 p-0">
                            <div className="btn-link link"><u>{`${item.brand} ${item.model}`}</u></div>
                        </div>
                    )
                }
            </section>
            {
                modelItems
                    ? <section className="border-top border-secondary pt-3 pb-3 pl-lg-0 pr-lg-0 col-12 row m-0">
                        <div className="col-12 pb-3 p-0"><h2>Alle {currentBrand} modellen</h2></div>
                        {
                            modelItems.map(
                                (model: any, index: number) => <div
                                    key={index}
                                    onClick={() => setLoadData({brand: currentBrand, model: model})}
                                    className="col-6 col-md-3 m-0 p-0">
                                    <div className="btn-link link"><u>{model}</u></div>
                                </div>
                            )
                        }
                    </section>
                    : null
            }
        </>
    )
};
