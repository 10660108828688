import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import {MainHomePage} from './MainHomePage';
import afbeelding_home from '../../../images/afbeelding_home.jpg';
import {FormList} from "./searchForm/FormList";
import {QueryRenderer} from "../list/QueryRenderer";
import {fetchHomePageQueryContainer, QueryDto} from "../../util/RestQueries";
import Spinner from "react-bootstrap/Spinner";
import {useHistory} from "react-router-dom";
import {Banner, Banners, callForBanners} from "./Banner";
import {getSessionStorageProperty} from "../../util/Session";
import {pushNewSearch} from "../../util/reactUtils";
import {safeScrollTop} from "../../util/domainUtils";


const HomePageInternal = (props: { data: any, setBrand: any, brand: any, formQuery: any }) => {
    const {advertisers} = props.data || {data: {advertisers: {}}};
    const carouselItems = Object.values(advertisers);
    const history = useHistory();

    const [banners, setBanners] = useState(new Banners([]));
    useEffect(() => {
        return callForBanners(setBanners);
    }, []);


    return (
        <>
            <div className="wrapper position-relative">
                <div className="home-page-header-img mt-xl-4 pt-xl-5 mt-lg-1 pt-lg-4 pl-2 mt-md-2 d-none d-sm-none d-md-block d-xl-block d-xg-block">
                    <h1 className="mt-2 text-main-home">
                        Het <strong>zuinigste</strong> aanbod<br />
                        <strong>occasions</strong> in Nederland<br />
                        vindt je op <a href={'https://rijzuinig.nl'} className="font-weight-bold text-dark">Rijzuinig.nl</a>
                    </h1>
                </div>
                <div className="d-md-none text-center p-3 home-page-header">
                    <h1 className="m-0 text-main-home">
                        Het <strong>zuinigste</strong> aanbod<br />
                        <strong>occasions</strong> in Nederland<br />
                        vindt je op <a href={'https://rijzuinig.nl'} className="font-weight-bold text-dark">Rijzuinig.nl</a>
                    </h1>
                </div>
            </div>

            <section className="d-none d-sm-none d-md-block d-xl-block d-xg-block mx-auto">
                <img className="w-100 main-image" src={afbeelding_home} alt="mainImage"/>
            </section>
            <section className="wrapper row mb-5">
                <FormList
                    formQuery={props.formQuery}
                    submitQuery={(query: any) => {
                        safeScrollTop("ALL")
                        pushNewSearch(history, query);

                    }}/>
                <MainHomePage carouselItems={carouselItems} banners={banners}/>
                <aside className="d-none d-md-block col-12 col-md-2">
                    <Row>
                        <section className="col-6 col-md-12 p-3">
                            <Banner banner_id={"HOME_160x600_A"} width={160} height={600} banners={banners}/>
                        </section>
                        <section className="col-6 col-md-12 p-3">
                            <Banner banner_id={"HOME_160x600_B"} width={160} height={600} banners={banners}/>
                        </section>

                        {/* <section className="col-6 col-md-12 p-3">
                            <div>
                                <a href="https://inkoopconcurrent.nl/" target="_blank" rel="noreferrer">
                                    <img src={inko} alt="inko"/>
                                </a>
                            </div>
                        </section>
                        <section className="col-6 col-md-12 p-3">
                            <div>
                                <a href="http://kentekenverpanden.nl/" target="_blank" rel="noreferrer">
                                    <img src={kenteken} alt="kenteken"/>
                                </a>
                            </div>
                        </section>*/}
                    </Row>
                </aside>
            </section>
        </>
    );
};


export const HomePage = (props: any) => {
    const [brand, setBrand] = useState('Merk...');
    const formQuery = getSessionStorageProperty("queryDto") || {} as QueryDto;

    return <QueryRenderer
        query="HomeQuery"
        fetchResponse={() => fetchHomePageQueryContainer()}
        renderOnOK={response => {
            // console.log("response", response);
            return <HomePageInternal data={response.data} formQuery={formQuery} setBrand={setBrand}
                                     brand={brand}/>
        }}
        renderOnError={<div className="w-100 text-center" style={{padding: "40vh", color: "red"}}>maintenance...</div>}
        renderOnStart={<div className="w-100 text-center mt-5 mb-5"><Spinner animation="border"/></div>}
    />
}

