import {MutableRefObject} from "react";
import {isMobile} from "react-device-detect";

export const formatPrice = (price: any): string => {
    if (price != null && !isNaN(price)) {
        const p = +price;
        let str = p.toLocaleString('en-US', {maximumFractionDigits: 2})
        // console.log(str);
        str = str.split(',').join('.');
        return str;
    }
    return price;
}

export const formatWithSuffix = (value: string | null, suffix: string, ifnull: string): string => {
    if (value)
        return value + suffix;
    else return ifnull;
}

//2012-11-15
export const reverseYearMonthDate = (value: string): string | null => {
    if (value) {
        let yearMonthDate = value.split("-");
        if (yearMonthDate.length === 3) {
            return yearMonthDate[2] + "-" + yearMonthDate[1] + "-" + yearMonthDate[0];
        }
        return value;
    } else
        return value;
}
// tsc domainUtils.ts | node domainUtils.js
// console.log("ha "+formatPrice("49944.00"))
// console.log("haa "+formatPrice("499.00"))

export const safeScroll = (resultsStatRef: MutableRefObject<any>, type: 'MOBILE' | 'DESKTOP' |'ALL' = 'ALL') => {
    const shouldScroll = type==='ALL' || (type==='MOBILE' && isMobile) || (type==='DESKTOP' && !isMobile);
    if (shouldScroll && resultsStatRef!=null && resultsStatRef.current!=null) {
        try {
            resultsStatRef.current.scrollIntoView();
        } catch (e) {
            console.log(e);
        }
    }
}

/* scrolls to top of the page */
export const safeScrollTop = (type: 'MOBILE' | 'DESKTOP' |'ALL' = 'ALL') => {
    const shouldScroll = type==='ALL' || (type==='MOBILE' && isMobile) || (type==='DESKTOP' && !isMobile);
    if (shouldScroll) {
        try {
            window.scrollTo(0, 0);
        } catch (e) {
            console.log(e);
        }
    }
}

export const getPartBefore = (str: string, delim: string) => {
    if (str==null)
        return "";
    const index=str.indexOf(delim);
    if (index<0)
        return str;
    else return str.substr(0, index);
}
export const getPartAfter = (str: string, delim: string) => {
    if (str==null)
        return "";
    const index=str.indexOf(delim);
    if (index<0)
        return str;
    else return str.substr(index+delim.length);
}