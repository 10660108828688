import React, { useState } from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DropdownMinMax } from '../dropdowns/DropdownMinMax';
import { itemsSeats } from "../../../../Constants";
import {QueryDto} from "../../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../../apis";


export const SeatsContainer = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;
    const { addDropdownMax, addDropdownMin, countCheckboxDropdownMinMax, clearForm, setClearForm } = props;

    const [seatsMin, _setSeatsMin] = useState(queryDto.seats_min /*getSessionStorageProperty('seatsMin')*/ || 'Min.');
    const [seatsMax, _setSeatsMax] = useState(queryDto.seats_max /*getSessionStorageProperty('seatsMax')*/ || 'Max.');

    const setSeatsMin = (value: any) => {
        queryDto.seats_min=value;
        saveQuery('seats_min', value);
        _setSeatsMin(value);
    }
    const setSeatsMax = (value: any) => {
        queryDto.seats_max=value;
        saveQuery('seats_max', value);
        _setSeatsMax(value);
    }

    if ((clearForm || queryDto.seats_min===undefined) && seatsMin !== 'Min.') {
        _setSeatsMin('Min.');
    }

    if ((clearForm || queryDto.seats_max===undefined) && seatsMax !== 'Max.') {
        _setSeatsMax('Max.');
    }
 
    return (
        <Form.Group
            className="col-12 col-md-6 col-lg-3 p-1"
            controlId="dropdown-seats"
        >
            <Form.Label className="font-weight-bold">
                Zitplaatsen
                    </Form.Label>
            <Row className="m-0">
                <DropdownMinMax
                    id={'seatsMin'}
                    idCompare={'seatsMax'}
                    arrayForItems={itemsSeats}
                    addDropdown={addDropdownMin}
                    setTitle={setSeatsMin}
                    title={seatsMin}
                    titleCompare={seatsMax}
                    setTitleCompare={setSeatsMax}
                    classProperties={"dropdown-min-max col-6 p-0 m-0"}
                    countCheckboxDropdownMinMax={countCheckboxDropdownMinMax}
                    initialValue={'min'}
                    setClearForm={setClearForm}
                    checkVisibleForm={props.checkVisibleForm}
                />
                <DropdownMinMax
                    id={'seatsMax'}
                    idCompare={'seatsMin'}
                    arrayForItems={itemsSeats}
                    addDropdown={addDropdownMax}
                    setTitle={setSeatsMax}
                    title={seatsMax}
                    titleCompare={seatsMin}
                    setTitleCompare={setSeatsMin}
                    classProperties={"dropdown-min-max col-6 p-0 m-0"}
                    countCheckboxDropdownMinMax={countCheckboxDropdownMinMax}
                    initialValue={'max'}
                    setClearForm={setClearForm}
                    checkVisibleForm={props.checkVisibleForm}
                />
            </Row>
        </Form.Group>
    )
}