import React, {useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {DropdownMinMaxPower} from "../dropdowns/DropdownMinMaxPower";
import {itemsPower} from "../../../../Constants"
import {QueryDto} from "../../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../../apis";

export const PowerContainer = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;
    const {addDropdownMax, addDropdownMin, countCheckboxDropdownMinMax, clearForm, setClearForm} = props;

    const [powerMin, _setPowerMin] = useState(queryDto.power_min /*getSessionStorageProperty('powerMin')*/ || 'Min.');
    const [powerMax, _setPowerMax] = useState(queryDto.power_max /*getSessionStorageProperty('powerMax')*/ || 'Max.');

    const setPowerMin = (value: any) => {
        queryDto.power_min=value;
        saveQuery('power_min', value);
        _setPowerMin(value);
    }
    const setPowerMax = (value: any) => {
        queryDto.power_max=value;
        saveQuery('power_max', value);
        _setPowerMax(value);
    }

    if ((clearForm || queryDto.power_min===undefined) && powerMin !== 'Min.') {
        _setPowerMin('Min.');
    }

    if ((clearForm || queryDto.power_max===undefined) && powerMax !== 'Max.') {
        _setPowerMax('Max.');
    }

    return (
        <Form.Group
            className="col-12 col-md-6 col-lg-3 p-1"
            controlId="dropdown-power-mine"
        >
            <Form.Label className="font-weight-bold">
                Vermogen (Kw/Pk)
            </Form.Label>
            <Row className="m-0">
                <DropdownMinMaxPower
                    id={'powerMin'}
                    idCompare={'powerMax'}
                    arrayForItems={itemsPower}
                    addDropdown={addDropdownMin}
                    setTitle={setPowerMin}
                    title={powerMin}
                    titleCompare={powerMax}
                    setTitleCompare={setPowerMax}
                    classProperties={"dropdown-min-max col-6 p-0 m-0"}
                    countCheckboxDropdownMinMax={countCheckboxDropdownMinMax}
                    initialValue={'min'}
                    setClearForm={setClearForm}
                />
                <DropdownMinMaxPower
                    id={'powerMax'}
                    idCompare={'powerMin'}
                    arrayForItems={itemsPower}
                    addDropdown={addDropdownMax}
                    setTitle={setPowerMax}
                    title={powerMax}
                    titleCompare={powerMin}
                    setTitleCompare={setPowerMin}
                    classProperties={"dropdown-min-max col-6 p-0 m-0"}
                    countCheckboxDropdownMinMax={countCheckboxDropdownMinMax}
                    initialValue={'max'}
                    setClearForm={setClearForm}
                />
            </Row>
        </Form.Group>
    )
}