import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {setValuesSessionStorage} from "../../../../util/Session";

export const DropdownMinMaxPower = (props: any) => {
    const {
        // setAmountAdditionalFields,
        countCheckboxDropdownMinMax,
        // conditionShowItems,
        classProperties,
        arrayForItems,
        initialValue,
        addDropdown,
        setTitle,
        titleCompare,
        setTitleCompare,
        idCompare,
        title,
        id,
        setClearForm,
    } = props;

    const isMinMax = (titleDropdown: any) => (titleDropdown === 'Min.') || (titleDropdown === 'Max.');

    const setValue = (titleCurrent: any, titleCompare: any) => {
        setTitle(titleCurrent);
        setTitleCompare(titleCompare)
    };

    const setTitleSessionStorage = (titleCurrent: any, titleCompare: any) => {
        setValuesSessionStorage(idCompare, titleCompare);
        setValuesSessionStorage(id, titleCurrent);
    };

    const compareValue = (value: any) => {

        const isMinMaxAny = !isMinMax(value) && !isMinMax(titleCompare);
        const isValueMore = value > titleCompare;

        if (
            (isMinMaxAny && isValueMore && initialValue === 'min')
            || (isMinMaxAny && !isValueMore && initialValue === 'max')
        ) {
            setValue(titleCompare, value);
            setTitleSessionStorage(titleCompare, value);
            return;
        }

        if (
            (isMinMaxAny && isValueMore && initialValue === 'max')
            || (isMinMaxAny && !isValueMore && initialValue === 'min')
        ) {
            setValue(value, titleCompare);
            setTitleSessionStorage(value, titleCompare);
            return;
        }

        setTitle(value);
        setValuesSessionStorage(id, value);
        setClearForm(false);
    }

    return (
        <Dropdown className={classProperties}>
            {
                !isMinMax(title)
                    ? <Dropdown.Toggle variant="light" id={"dropdown-" + id}
                                       className="shadow-none bg-white text-secondary" data-value={title}>
                        <div> {title}
                            <span className="small">Kw</span>
                            {`/${Math.ceil(title * 1.36)}`}
                            <span className="small">Pk</span>
                        </div>
                    </Dropdown.Toggle>
                    : <Dropdown.Toggle variant="light" id={"dropdown-" + id}
                                       className="shadow-none bg-white text-secondary" data-value={title}>
                        {title}
                    </Dropdown.Toggle>
            }
            <Dropdown.Menu>
                {
                    addDropdown(arrayForItems)
                        .map(
                            (item: any, index: number) => {
                                if (index !== 0) {
                                    return (
                                        <Dropdown.Item
                                            key={index}
                                            onClick={() => {
                                                compareValue(item)
                                                countCheckboxDropdownMinMax(id, item)
                                            }}
                                            active={+title === item}
                                        >
                                            {item}
                                            <span className="small">Kw</span>
                                            {`/${Math.ceil(item * 1.36)}`}
                                            <span className="small">Pk</span>
                                        </Dropdown.Item>
                                    )
                                }
                                return <Dropdown.Item
                                    key={index}
                                    onClick={() => {
                                        compareValue(item)
                                        countCheckboxDropdownMinMax(id, item)
                                    }}
                                    active={title === item}
                                >
                                    {item}
                                </Dropdown.Item>

                            }
                        )
                }
            </Dropdown.Menu>
        </Dropdown>
    )
};
