import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { DropdownFormInput } from "./dropdowns/DropdownFormInput";
import { itemsFuel } from "../../../Constants";
import {QueryDto} from "../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../apis";


export const FuelContainer = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;
    const [fuelPlaceholder, _setFuelPlaceholder] = useState(queryDto.fuel || 'Brandstof...');
    const [filteredFuelItems, setFilteredFuelItems] = useState(itemsFuel);
    const [inputFuelValue, setInputFuelValue] = useState('');

    const setFuelPlaceholder = (value: any) => {
        queryDto.fuel=value;
        saveQuery('fuel', value);
        _setFuelPlaceholder(value);
    }
    if ((props.clearForm || queryDto.fuel === undefined) && fuelPlaceholder !== 'Brandstof...') {
        _setFuelPlaceholder('Brandstof...');
    }

    return (
        <Form.Group controlId="dropdownFuel" className="col-12 col-md-6 col-lg-3 p-1">
            <DropdownFormInput
                filteredItems={filteredFuelItems}
                getDropDownItems={props.getDropDownItems}
                placeholder={fuelPlaceholder}
                inputValue={inputFuelValue}
                value={'fuel'}
                countInputs={() => false}
                setPlaceholder={setFuelPlaceholder}
                setInputValue={setInputFuelValue}
                setFilteredItems={setFilteredFuelItems}
                dropDownItems={itemsFuel}
                setClearForm={props.setClearForm}
                checkVisibleForm={props.checkVisibleForm}
            />
        </Form.Group>
    )
}
