import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

export const DropdownRadius = (props: any) => {
    const {
        radiusPlaceholder,
        inputRadiusValue,
        getDropDownItems,
        filteredRadiusItems,
        setRadiusPlaceholder,
        setFilteredRadiusItems,
        setInputRadiusValue,
        radiusItems,
        setClearForm,
        // TODO perhaps?       checkVisibleForm,
    } = props;

    const handleChange = (valueInput: any) => {
        setInputRadiusValue(valueInput);
        setRadiusPlaceholder(valueInput);
        setClearForm(false);
    };
    const filterTitles = (valueInput: any) => {
        const toLowerCaseValue = valueInput.toLowerCase();

        setInputRadiusValue(valueInput);
        setFilteredRadiusItems(radiusItems.filter((str: string) => str.toLowerCase().indexOf(toLowerCaseValue) >= 0));
    };

    return (
        <Dropdown
            className="p-0 bg-white border-form-input col-7 col-md-7"
            onSelect={(eventKey) => {
                handleChange(eventKey)
            }}
        >
            <Dropdown.Toggle
                id="dropdown-radius"
                as="div"
                className="w-100 bg-white"
            >
                <input
                    type="text"
                    placeholder={radiusPlaceholder}
                    className="bg-white w-100 pl-3 text-secondary"
                    value={inputRadiusValue}
                    onChange={(event) => {
                        filterTitles(event.currentTarget.value)
                    }}
                />
            </Dropdown.Toggle>

            <Dropdown.Menu className="col-md-3 m-0 p-0">
                {getDropDownItems(filteredRadiusItems, radiusPlaceholder)}
            </Dropdown.Menu>
        </Dropdown>
    )
};
