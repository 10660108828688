import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { DropdownFormInput } from "./dropdowns/DropdownFormInput";
import { itemsTransmissions } from "../../../Constants";
import {QueryDto} from "../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../apis";


export const TransmissionsContainer= (props: {
        queryDto: QueryDto, saveQuery: SaveQueryDefault
    } & any) => {
        const queryDto: QueryDto = props.queryDto;
        const saveQuery: SaveQueryDefault = props.saveQuery;
    const [transmissionsPlaceholder, _setTransmissionsPlaceholder] = useState(queryDto.transmission /*getSessionStorageProperty('transmissionsPlaceholder')*/ || 'Transmissie...');
    const [filteredTransmissionsItems, setFilteredTransmissionsItems] = useState(itemsTransmissions);
    const [inputTransmissionsValue, setInputTransmissionsValue] = useState('');

    const setTransmissionsPlaceholder = (value: any) => {
        queryDto.transmission=value;
        saveQuery('transmission', value);
        _setTransmissionsPlaceholder(value);
    }
    if ((props.clearForm || queryDto.transmission===undefined) && transmissionsPlaceholder !== 'Transmissie...') {
        _setTransmissionsPlaceholder('Transmissie...');
    }

    return (
        <Form.Group className="col-12 col-md-6 col-lg-3 p-1" controlId="dropdownTransmissions">
            <DropdownFormInput
                filteredItems={filteredTransmissionsItems}
                getDropDownItems={props.getDropDownItems}
                placeholder={transmissionsPlaceholder}
                inputValue={inputTransmissionsValue}
                value={'transmissions'}
                countInputs={() => false}
                setPlaceholder={setTransmissionsPlaceholder}
                setInputValue={setInputTransmissionsValue}
                setFilteredItems={setFilteredTransmissionsItems}
                dropDownItems={itemsTransmissions}
                setClearForm={props.setClearForm}
                checkVisibleForm={props.checkVisibleForm}
            />
        </Form.Group>
    )
}
