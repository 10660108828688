import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import {QueryDto} from "../../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../../apis";

export const DescriptionContainer = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;
    const { countInputs, clearForm, checkVisibleForm, setClearForm } = props;
    const [ keyword, _setKeyword ] = useState(queryDto.description/*getSessionStorageProperty('description')*/ || '');


    const setKeyword = (value: any) => {
        queryDto.description=value;
        saveQuery('description', value);
        _setKeyword(value);
        if (value !== '')
            setClearForm(false);
        checkVisibleForm(0, value);
    }

    if ((clearForm || queryDto.description===undefined || queryDto.description==='') && keyword !== '') {
        queryDto.description='';
        _setKeyword('');
    }

    return (
        <Form.Group
            controlId="keyword-wrapper"
            className="col-12 col-md-6 col-lg-3 p-1"
        >
            <Form.Label className="font-weight-bold">
                Trefwoord
            </Form.Label>
            <Form.Control
                type="text"
                className="w-100 rounded-0 border-form-input"
                /*controlId="keyword" // TODO commented */
                onChange={(event: any) => setKeyword(event.target.value)}
                onBlur={(event: any) => countInputs('keyword', event.target.value)}
                value={keyword}
            />
        </Form.Group>
    )
}