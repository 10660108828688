import React, {MutableRefObject} from 'react';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
//import { SeoTextContainer } from './SeoTextContainer';
//import { AdvertisementItemList } from './AdvertisementItemList';
import Dropdown from 'react-bootstrap/Dropdown';
import sort_icon from '../../../images/sort_icon.png';
import {ListItem} from './ListItem';
import {SeoTextContainer} from "./SeoTextContainer";
import {useHistory} from 'react-router-dom';
import {jsonToQueryString} from "../../util/RestQueries";
import {Banner, Banners} from "../home/Banner";
import {safeScrollTop} from "../../util/domainUtils";
//import Router from 'next/router';

export const TableList = (props: {
    brand: any, getPages: any, sortList: any,
    advertiser: any, setLoadData: any, dataQuery: any,
    amountCarPage: any, setAmountCarPerPage: any, offset: any,
    orderBy?: string,
    sortBy?: string,
    banners: Banners,
    resultsStatRef: MutableRefObject<any>
}) => {
    const history = useHistory();

    const {
        brand,
        getPages,
        sortList,
        advertiser,
        setLoadData,
        dataQuery,
        amountCarPage,
        setAmountCarPerPage,
        orderBy,
        sortBy,
        resultsStatRef
    } = props;
    let startRow = props.offset || 0;

    const count_hexon_id = advertiser && advertiser.length > 0 ? advertiser[0].count_hexon_id : 0;

    const numberOfPagination = count_hexon_id / amountCarPage;
    const arrayOfAdvertisers = advertiser && count_hexon_id > 0 ? Object.values(advertiser) : [];
    const styleItemLi = 'page-item d-none d-md-inline-block';
    const styleItemGreen = 'bg-success text-white page-link mr-2 cursor-pointer rounded-0';
    const pages =
        count_hexon_id % amountCarPage === 0 ? numberOfPagination : Math.floor(numberOfPagination) + 1;
    const arrayForPagination = Array(pages).fill({});
    // console.log("count_hexon_id "+count_hexon_id+" "+amountCarPage, arrayForPagination);
    const dropdownTitles = [
        'Relevantie',
        'Prijs laag naar hoog',
        'Prijs hoog naar laag',
        'Km stand laag naar hoog',
        'Km stand hoog naar laag',
        'Bouwjaar oud naar nieuw',
        'Bouwjaar nieuw naar oud',
    ];
    const amountPages = ['10', '20', '50'];
    let isShowingButtonPagination = false;


    const detectSortByTitle = (): string => {
        // orderBy=km_travelled&sortBy=ASC
        if (orderBy === 'km_travelled') {
            if (sortBy === 'DESC') {
                return 'Km stand hoog naar laag';
            } else {
                return 'Km stand laag naar hoog';
            }
        }
        if (orderBy === 'price_excluding_vat') {
            if (sortBy === 'DESC') {
                return 'Prijs hoog naar laag';
            } else {
                return 'Prijs laag naar hoog';
            }
        }
        if (orderBy === 'year_of_production') {
            if (sortBy === 'DESC') {
                return 'Bouwjaar nieuw naar oud';
            } else {
                return 'Bouwjaar oud naar nieuw';
            }
        }

        return "Relevantie";
    }
    const titleDropdown = detectSortByTitle();

    const openCarPage = (advertisement_id: any) => {
        const currentListQuery = {
            ...dataQuery,
        };

        history.push({pathname: '/car/' + advertisement_id, search: jsonToQueryString(currentListQuery)})
        safeScrollTop("ALL");
    };

    const listItems = arrayOfAdvertisers.map((advertiser: any, index: number) => {
        const {/*hexon_id, */advertisement, specification, name, city} = advertiser;
        const car_id = advertisement.id;
        const {brand, title, pricing, feature_image} = advertisement;
        const {
            model,
            km_travelled,
            energy_label,
            year_of_production,
            engine_capacity_cc,
            fuel,
            transmission,
            type,
            color,
            number_of_doors,
            horse_power,
            bovag_warranty,
            brand_warranty,
            nap,
            options,
        } = specification;
        return (
            <tr className="cursor-pointer" key={index} onClick={() => openCarPage(car_id)}>
                <ListItem
                    title={title}
                    pricing={pricing}
                    feature_image={feature_image}
                    km_travelled={km_travelled}
                    year_of_production={year_of_production}
                    brand={brand}
                    model={model}
                    energy_label={energy_label}
                    engine_capacity_cc={engine_capacity_cc}
                    fuel={fuel}
                    transmission={transmission}
                    type={type}
                    color={color}
                    number_of_doors={number_of_doors}
                    horse_power={horse_power}
                    name={name}
                    city={city}
                    bovag_warranty={bovag_warranty}
                    brand_warranty={brand_warranty}
                    nap={nap}
                    options={options}
                />
            </tr>
        );
    });

    let j = 0;
    for (let i = 10; i < listItems.length; i += 11) {
        const advert = <tr key={"adver" + i}>
            <td className="m-0 p-0 p-md-5 pt-2 col-12">


                <div className="advertisement-item-list m-auto bg-secondary">
                    <Banner banner_id={"RESULTS_728x90_A"} width={undefined} height={undefined} offset={j++ * 13}
                            banners={props.banners}/>
                </div>
            </td>
        </tr>;
        listItems.splice(i, 0, advert);
    }


    // Start pagination
    const paginationItems = arrayForPagination.map((_, index) => {
        const styleItemDark = 'bg-dark text-white page-link mr-2 rounded-0';

        return (
            <li key={index} className={styleItemLi} onClick={() => getPages(index)}>
        <span className={index === (startRow || 0) / amountCarPage ? styleItemDark : styleItemGreen}>
          {index + 1}
        </span>
            </li>
        );
    });

    const getActivePages = () => {
        const {length} = paginationItems;
        const paginationItemsLengthMinusThree = length - 3;
        let offset = startRow || 0;

        const activePageOverThirty = offset > 150;
        const paginationOverTen = length > 10;
        const activePagePerTen = offset / amountCarPage;

        const currentPage = offset / amountCarPage;


        let firstArg = 0;
        let secondArg;

        const addThreeDotInsidePagination = (paginationItem: any = 0): JSX.Element => {
            const index = paginationItem ? length - 1 : 0;
            return (
                <li key={Math.random()} className={styleItemLi} onClick={() => getPages(index)}>
                    <span className="bg-success text-white page-link mr-2 rounded-0 cursor-pointer">...</span>
                </li>
            );
        };
        // console.log("activePage "+offset+" amountPerPage "+amountCarPage+" currentPage: "+currentPage);


        const getActivePaginationItem = (firstArg: any, secondArg: any, array: any[]) =>
            array ? array.slice(firstArg, secondArg) : undefined;


        const total = 10;
        firstArg = secondArg = currentPage;
        while (secondArg - firstArg < total - 1) {
            firstArg--;
            secondArg++;
            firstArg = Math.max(0, firstArg);
            secondArg = Math.min(secondArg, length);
            if (firstArg <= 0 && secondArg >= length)
                break;
        }

        /* if (firstArg==1) {
             firstArg = 0;
         }
         if (secondArg==length-1)
             secondArg=length;*/

        const paginationItemsWithDots = getActivePaginationItem(firstArg, secondArg, paginationItems) || [];
        if (true) {

            if (firstArg > 0) {
                paginationItemsWithDots.unshift(addThreeDotInsidePagination());
            }
            if (secondArg < length) {
                paginationItemsWithDots.push(addThreeDotInsidePagination('last'));
            }
            return paginationItemsWithDots;
        }


        if (paginationOverTen && activePageOverThirty) {
            firstArg = activePagePerTen - 3;
        }
        if (paginationOverTen && activePagePerTen < paginationItemsLengthMinusThree) {
            secondArg = activePagePerTen + 4;
        }
        if (activePagePerTen < 4) {
            secondArg = 7;
        }
        if (secondArg === undefined) {
            secondArg = 0;
        }
        //console.log("firstArg "+firstArg+" "+secondArg);
        if (activePageOverThirty && secondArg < length) {
            const paginationItemsWithDots = getActivePaginationItem(firstArg, secondArg, paginationItems) || [];
            paginationItemsWithDots.unshift(addThreeDotInsidePagination());
            paginationItemsWithDots.push(addThreeDotInsidePagination('last'));
            isShowingButtonPagination = true;

            return paginationItemsWithDots;
        }
        if (firstArg < 4 && secondArg < paginationItemsLengthMinusThree) {
            const paginationItemsWithDots = getActivePaginationItem(firstArg, secondArg, paginationItems) || [];
            paginationItemsWithDots.push(addThreeDotInsidePagination('last'));
            isShowingButtonPagination = true;

            return paginationItemsWithDots;
        }
        if (activePageOverThirty && activePagePerTen > 7) {
            const paginationItemsWithDots = getActivePaginationItem(firstArg, secondArg, paginationItems) || [];
            paginationItemsWithDots.unshift(addThreeDotInsidePagination());

            return paginationItemsWithDots;
        }

        return paginationItems;
    };

    return (
        <>
            <Row ref={resultsStatRef} className="col-12 p-0 m-0 pt-3 pt-md-0 d-flex justify-content-between">
                <section className="col-3">
                    <h3 className="font-weight-bold">Occasions</h3>
                    <span style={{whiteSpace: "nowrap"}}>{count_hexon_id} resultaten</span>
                </section>
                <section className="col-6 d-flex justify-content-end">
                    <section>
                        <Dropdown
                            className="m-0 col-12 p-0 text-right list-dropdown"
                            onSelect={(event) => sortList(event)}
                        >
                            <Dropdown.Toggle variant="btn-link" id="dropdown-basic" className="p-2">
                                <section className="d-none d-sm-inline-block">
                                    {`Sorteer Op: `}
                                    <u>{titleDropdown}</u>
                                </section>
                                <section className="d-inline-block d-sm-none sort-icon-wrraper">
                                    <img className="w-100" src={sort_icon} alt="iconSort"/>
                                </section>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {dropdownTitles.map((item, index) =>
                                    item !== titleDropdown ? (
                                        <Dropdown.Item key={index} eventKey={item}>
                                            {item}
                                        </Dropdown.Item>
                                    ) : (
                                        <Dropdown.Item key={index} eventKey={item} active>
                                            {item}
                                        </Dropdown.Item>
                                    ),
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </section>
                    {/* Car per page */}
                    <section>
                        <Dropdown
                            className="m-0 col-12 p-0 text-right list-dropdown"
                            onSelect={(event) => setAmountCarPerPage(event)}
                        >
                            <Dropdown.Toggle variant="btn-link" id="dropdown-basic" className="p-2">
                                <section className="d-none d-sm-inline-block">
                                    {`Aantal per pagina: `}
                                    <u>{amountCarPage}</u>
                                </section>
                                <section className="d-inline-block d-sm-none sort-icon-wrraper">
                                    <img className="w-100" src={sort_icon} alt="iconSort"/>
                                </section>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {amountPages.map((item, index) =>
                                    item ? (
                                        <Dropdown.Item key={index} eventKey={item}>
                                            {item}
                                        </Dropdown.Item>
                                    ) : (
                                        <Dropdown.Item key={index} eventKey={item} active>
                                            {item}
                                        </Dropdown.Item>
                                    ),
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </section>
                </section>
            </Row>
            <table className="col-12 mt-4">
                <tbody>
                {listItems}
                {/*   {count_hexon_id > 0 ? listItems.slice(0, 6) : null}
<tr>
                                    <td className="col-12 m-0 p-5">
                                        <div className="advertisement-item-list m-auto bg-secondary"></div>
                                    </td>
                                </tr>
                {count_hexon_id > 0 ? listItems.slice(6) : null}*/}
                </tbody>
            </table>
            { /* This code like a shit  */}
            <Row className={`p-0 m-0 ${count_hexon_id <= amountCarPage ? 'd-none' : ''}`}>
                <section className="d-none d-md-inline-block col-md-8">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            {getActivePages()}
                            {isShowingButtonPagination ? (
                                <li
                                    key={Math.random()}
                                    className={styleItemLi}
                                    onClick={() => getPages(paginationItems.length - 1)}
                                >
                  <span className="bg-success text-white page-link mr-2 rounded-0 cursor-pointer">
                    {arrayForPagination.length}
                  </span>
                                </li>
                            ) : null}
                        </ul>
                    </nav>
                </section>
                <section className="col-12 col-md-4 text-md-right row m-0 pb-3 p-md-0">
                    <section className="col-6">
                        <Button
                            variant="success"
                            className="rounded-0 w-100"
                            onClick={() => getPages('prev')}
                            disabled={+startRow === 0}
                        >
                            Vorige
                        </Button>
                    </section>
                    <section className="col-6">
                        <Button
                            variant="success"
                            className="rounded-0 w-100"
                            onClick={() => getPages('next', +count_hexon_id)}
                            disabled={(+startRow + +amountCarPage) >= count_hexon_id}
                        >
                            Volgende
                        </Button>
                    </section>
                </section>
            </Row>
            { /* This code like a shit end :) */}
            {brand && brand !== 'Merk...' &&
            <SeoTextContainer currentBrand={brand} setLoadData={setLoadData} dataQuery={dataQuery}/>}
            <section className="border-top border-secondary p-3 pt-3 pb-3 pl-lg-0">
                <p className="m-0">
                    <a href="/home" className="text-dark">
                        <u>Rijzuinig.nl</u>
                    </a>
                    {brand && brand !== 'Merk...' ? ` / ${brand}` : null}
                </p>
            </section>
        </>
    );
};

/*
export default createFragmentContainer(TableList, {
  advertisers: graphql`
    fragment TableList_advertisers on Advertiser {
      hexon_id
      name
      city
      advertisement {
        pricing {
          price_excluding_vat
        }
        media {
          width
          url
          height
        }
        title
        brand
        description
        feature_image
      }
      specification {
        model
        km_travelled
        energy_label
        year_of_production
        engine_capacity_cc
        fuel
        transmission
        type
        color
        number_of_doors
        horse_power
        bovag_warranty
        brand_warranty
        nap
      }
      count_hexon_id
    }
  `,
});
*/
