import React, {useEffect, useState} from 'react';
// import axios from "axios";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import {DropdownBrand} from "./dropdowns/DropdownBrand";
import {DropdownModelContainer} from "./dropdowns/DropdownModelContainer";
import {getSessionStorageProperty} from "../../../util/Session";
import {fetchBrands, QueryDto} from "../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../apis";

export const BrandModelContainer = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;

    const [brandTitle, _setBrandTitle] = useState(queryDto.brand /*brandgetSessionStorageProperty('brandTitle')*/ || 'Merk...');
    const [brandTitles, setBrandTitles] = useState(null);
    const brandTitlesStorage = getSessionStorageProperty('brandItems');

    const setBrandTitle = (title: any) => {
        if (queryDto.brand !== title) {
            queryDto.brand = title;
            queryDto.model = null as any; // clear model
            saveQuery('brand', title);
            _setBrandTitle(title);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            // console.log("fetching brand data...")
            const response = await fetchBrands();
            setBrandTitles(response);
        };

        fetchData();
    }, []);

    if (props.clearForm && brandTitle !== 'Merk...') {
        _setBrandTitle('Merk...');
    }


    if (brandTitles) {
        return (
            <>
                <DropdownBrand
                    brandTitle={queryDto.brand || 'Merk...'}
                    setBrandTitle={setBrandTitle}
                    brandTitles={brandTitles}
                    brandTitlesStorage={brandTitlesStorage}
                    setClearForm={props.setClearForm}
                    checkVisibleForm={props.checkVisibleForm}
                />
                {
                    queryDto.brand !== 'Merk...' && queryDto.brand !== undefined
                        ? <DropdownModelContainer
                                                  brand={queryDto.brand}
                                                  queryDto={queryDto}
                                                  saveQuery={saveQuery}
                                                  checkVisibleForm={props.checkVisibleForm}
                                                  clearForm={props.clearForm} setClearForm={props.setClearForm}/>
                        : <Form.Group controlId="dropdownModel" className="col-md-6 col-lg-3 p-1">
                            <Dropdown className="bg-white p-0 border-form-input disabled">
                                <Dropdown.Toggle
                                    id="dropdown-basic"
                                    as="div"
                                    className="w-100 disabled"
                                >
                                    <input
                                        type="search"
                                        placeholder="Model..."
                                        className="w-100 text-secondary pl-3 disabled"
                                    />
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Form.Group>
                }
            </>
        )
    }

    return (
        <>
            <Form.Group controlId="dropdownBrand" className="col-md-6 col-lg-3 p-1">
                <Dropdown
                    className="p-0 bg-white border-form-input"
                >
                    <Dropdown.Toggle
                        id="dropdown-basic"
                        as="div"
                        className="w-100 bg-white"
                        disabled
                    >
                        <input
                            type="search"
                            placeholder="Merk..."
                            className="bg-white w-100 text-secondary pl-3"

                        />
                    </Dropdown.Toggle>
                </Dropdown>
            </Form.Group>
            <Form.Group controlId="dropdownModel" className="col-md-6 col-lg-3 p-1">
                <Dropdown className="bg-white p-0 border-form-input disabled">
                    <Dropdown.Toggle
                        id="dropdown-basic"
                        as="div"
                        className="w-100 disabled"
                    >
                        <input
                            type="search"
                            placeholder="Model..."
                            className="w-100 text-secondary pl-3 disabled"
                        />
                    </Dropdown.Toggle>
                </Dropdown>
            </Form.Group>
        </>
    )
}