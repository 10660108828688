import React from 'react';
import {formatPrice} from "../../util/domainUtils";
import rijzuinigskeuze from "../../../images/rijzuinigskeuze.png";

export const ListItem = (props: any) => {
  const {
    title,
    brand,
    model,
    feature_image,
    pricing,
    km_travelled,
    year_of_production,
    energy_label,
    engine_capacity_cc,
    fuel,
    transmission,
    type,
    color,
    horse_power,
    number_of_doors,
    name,
    city,
    options
  } = props;
  return (
    <td className="row  m-0 p-4 p-3 mb-3 tr-table" style={{maxWidth:'100vw'}}>
      <section className=" p-0 col-12 col-md-3 container img-card-carousel d-flex flex-column">

        <div>
        <img src={feature_image} className="w-100" alt="car" />
{/*        <section className="position-relative">
          <span className="letter-card-Item">
            <div className={`category-form text-center category-letter-${energy_label} text-white`}>
              {energy_label}
            </div>
          </span>
        </section>*/}
        <section className="bg-success d-md-none  text-center p-2">
          <span className="font-weight-bold text-white">
            &euro; {formatPrice(pricing[0].price_excluding_vat)}
          </span>
        </section>
        </div>
        {energy_label==='A' &&
        <div className="mt-auto p-2 d-block d-sm-none">&nbsp;</div>}
        {energy_label==='A' && <div className="m-0 p-1 pt-2 d-block d-sm-none">
           <img src={rijzuinigskeuze} style={{width: 100}}/>
          </div>}

      </section>
      <section className="pl-md-3 mt-4 mt-md-0 m-0 p-0 col-12 col-md-9 d-flex flex-column">
        <section className="p-0 m-0 row d-flex flex-nowrap">
          <section className="d-none d-md-inline-block p-0 ">
            <span className="font-weight-bold text-white bg-success p-2 pl-4 pr-4 d-inline-block text-nowrap">
              &euro; {formatPrice(pricing[0].price_excluding_vat)}
            </span>
          </section>
          <h5 className="ml-0 pl-0 pl-md-2 col-md-10">
            <b style={{wordBreak: 'break-word'}}>{title}</b>
          </h5>
        </section>
        <p className="m-0 item-car-details">
          Bouwjaar: {year_of_production}, KM stand: {km_travelled}km
        </p>
        <p className="item-car-details">
          {`${fuel}, ${engine_capacity_cc || 0}cc, ${
            horse_power || 0
          }Kw, ${transmission}, ${type}, ${color}, ${number_of_doors || 0} deurs`}
        </p>

        <p className="item-car-details d-flex " style={{alignItems: "center"}}>
        Energielabel: <div className={"ml-2 category-form text-center category-letter-"+energy_label+" text-white"}>
          {energy_label}
        </div>
        </p>

        <p className="item-car-details d-flex justify-content-between flex-grow-1 flex-fill m-0">
          <div className="flex-grow-1 flex-fill">{name}, {city}</div>
          {energy_label==='A' && <div className="m-0 d-none d-sm-flex justify-content-end align-items-end">
            <img src={rijzuinigskeuze} style={{width: 140}}/>
          </div>}
        </p>




      </section>
    </td>
  );
};
