import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {DropdownModel} from "./DropdownModel";
import Dropdown from "react-bootstrap/Dropdown";
//import axios from "axios";
import {setValuesSessionStorage} from "../../../../util/Session";
import {fetchModelsForBrands, QueryDto} from "../../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../../apis";

export const DropdownModelContainer = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const brand = props.brand;
    const saveQuery: SaveQueryDefault = props.saveQuery;
    const [modelItems, setModelItems] = useState(null);
    const [filteredModelItems, setFilteredModelItems] = useState(null);
    // const [modelTitle, _setModelTitle] = useState(queryDto.model /*getSessionStorageProperty('modelTitle')*/ || 'Model...');
    const [inputModelValue, setInputModelValue] = useState('');

    const setModelTitle = (value: any) => {
        if (queryDto.model !== value) {
            queryDto.model = value;
            saveQuery('model', value);
            //_setModelTitle(value);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            // _setModelTitle(queryDto.model || 'Model...');
            // console.log("Fetch models for brand "+brand);
            const result = await fetchModelsForBrands(brand);
            const modelItemsResponse = result.map((title: any) => title.name).sort();
            setModelItems(modelItemsResponse)
            setFilteredModelItems(modelItemsResponse);
            setValuesSessionStorage('modelItems', modelItemsResponse)
            setInputModelValue('');

        };

        fetchData();
    }, [brand, queryDto.model, queryDto.brand]);

    /*if (props.clearForm && modelTitle !== 'Model...') {
        _setModelTitle('Model...');
    }*/

    if (filteredModelItems) {
        return (
           <Form.Group controlId="dropdownModel" className="col-md-6 col-lg-3 p-1">
                <DropdownModel
                    filteredModelItems={filteredModelItems}
                    setFilteredModelItems={setFilteredModelItems}
                    modelTitle={queryDto.model || 'Model...'}
                    setModelTitle={setModelTitle}
                    inputModelValue={inputModelValue}
                    setInputModelValue={setInputModelValue}
                    modelItems={modelItems}
                    setClearForm={props.setClearForm}
                    checkVisibleForm={props.checkVisibleForm}
                />
            </Form.Group>
        )
    }

    return (
        <Form.Group controlId="dropdownModel" className="col-md-6 col-lg-3 p-1">
            <Dropdown
                className="p-0 bg-white border-form-input"
            >
                <Dropdown.Toggle
                    id="dropdown-basic"
                    as="div"
                    className="w-100 bg-white"
                >
                    <input
                        type="search"
                        placeholder='Model...'
                        className="bg-white w-100 pl-3"
                        defaultValue=''
                    />
                </Dropdown.Toggle>

                <Dropdown.Menu className="col-md-3 m-0 p-0">
                </Dropdown.Menu>
            </Dropdown>
        </Form.Group>
    )

};

