import {getSessionStorageProperty} from "./Session";
import {deleteUndefinedFields} from "./reactUtils";

export function jsonToQueryString(json: any, omitNulls: boolean = true) {
    if (json == null)
        return "";
    return '?' +
        Object.keys(json)
            .filter((key: string) => {
                return !omitNulls || json[key] != null
            })
            .map(function (key: string) {
                if (Array.isArray(json[key])) {
                    return json[key].map((k: any) => encodeURIComponent(key) + "=" + encodeURIComponent(k)).join('&')
                }
                return encodeURIComponent(key) + '=' +
                    encodeURIComponent(json[key]);
            }).join('&');
}

const host = "";//http://rijzuinig.evizone.com:8000";
export const fetchModelsForBrands = (brandName: string): Promise<any> => {
    return fetch(`${host}/api/models?brand=${brandName}`)
        .then(response => response.json());
}

export const fetchBrands = (): Promise<any> => {
    return fetch(`${host}/api/brands`)
        .then(response => response.json());
}

export const performQuery = (query: QueryDto, overridePagingAndSorting: boolean=false): Promise<any> => {
    if (overridePagingAndSorting) {
        query={...query}
        if (query.orderBy === undefined) {
            query.orderBy=getSessionStorageProperty("orderBy", undefined)
            query.sortBy=getSessionStorageProperty("sortBy", undefined)
        }
        if (query.next === undefined) {
            query.next=getSessionStorageProperty("amountCarPage", undefined)
        }
    }
    return fetch(host + "/api/search" + jsonToQueryString(query))
        .then(response => response.json())
        .then(r => {
            return r;
        });
}

export const fetchCarDetails = (carId: String): Promise<any> => {
    return fetch(host + "/api/cars/" + carId)
        .then(response => response.json())
}


export const fetchCeoForBrands = (brandName: string): Promise<any> => {
    return fetch(`${host}/api/ceo?brand=${brandName}`)
        .then(response => response.json())
}

export const fetchContentForPage = (pageName: string): Promise<any> => {
    return fetch(`${host}/api/pages/${pageName}`)
        .then(response => response.text())
}


export const fetchHomePageQueryContainer = (page?: {}): Promise<any> => {
    const itemPerPage = 12;
    const itemPerCarousel = itemPerPage * 3;
    return fetch(`${host}/api/search-home-container?next=${itemPerCarousel}&orderBy=created_at&sortBy=DESC`)
        .then(response => response.json())
        .then(r => {
            //console.log("RR", r);
            return r;
        });
}

export const fetchBanners = (): Promise<BannerDto[]> => {
    // console.log("fetch banners...");
    return fetch(`${host}/api/banners`)
        .then(response => response.json())
}


let sendPost = function (url: string, body: string) {
    return fetch(url, {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
        },
        body: body,
    })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            return true;
        })
        .catch((error) => {
            console.error('Error:', error);
            return false;
        })
};
export const sendContactEmail = (contact: ContactDto): Promise<Boolean> => {
    console.log("send contact email...");
    const url = `${host}/api/email/contact`;
    const body= JSON.stringify(contact);
    return sendPost(url, body);
}
export const sendContactEmailProvider = (contact: CarProviderContactDto): Promise<Boolean> => {
    console.log("send contact email to car provider...");
    const url = `${host}/api/email/contact-car-provider`;
    const body= JSON.stringify(contact);
    return sendPost(url, body);
}


export interface ContactDto {
    sender_name: string,
    sender_email: string,
    topic: string,
    question_or_remark: string,
}
export interface CarProviderContactDto {
    sender_name: string,
    sender_email: string,
    sender_phone_number: string,
    message: string,
    car_id: number,
    option_take_a_test_drive: boolean,
    option_trade_in_my_current_car: boolean,
    option_call_me_back: boolean,
    option_interested_in_financing: boolean,
}



export interface BannerDto {
    banner_id: string
    url: string,
    img_upload: string | null, // higher priority than img
    img: string | null,
    alt: string,
    rotation_order: number
}

export interface QueryDto {
    brand: string,// brand=Alfa%20Romeo
    model: string, // &model=Model...
    year_of_production_min: number,// &year_of_production_min=0
    year_of_production_max: number,// &year_of_production_max=10000000
    price_excluding_vat_min: number,// &price_excluding_vat_min=0
    price_excluding_vat_max: number,// &price_excluding_vat_max=10000000
    fuel: string, // &fuel=Brandstof...
    km_travelled_min: number, // &km_travelled_min=0
    km_travelled_max: number, // &km_travelled_max=10000000
    transmission: string, //&transmission=Transmissie...
    type: string  //&type=Carrosserie...
    label: string[] //&label=
    energyCounting: number
    description: string // &description=
    cylinder_capacity_min: number //&cylinder_capacity_min=0
    cylinder_capacity_max: number //&cylinder_capacity_max=10000000
    amount_capacity_min: number //&amount_capacity_min=0
    amount_capacity_max: number //&amount_capacity_max=10000000
    amount_cylinders_min: number
    amount_cylinders_max: number

    postcode: string //&postcode=Postcode
    radius: string// &radius=Straal...
    days: string, // &days=Geen%20voorkeur
    car_dealer: string, // &car_dealer=Geen%20voorkeur
    power_min: number,// &power_min=0
    power_max: number, // &power_max=10000000
    seats_min: number, // &seats_min=0
    seats_max: number,// &seats_max=10000000
    weight_min: number, // &weight_min=0
    weight_max: number,// &weight_max=10000000
    options: string[] //&options=lichtmetalen%20velgen&options=armsteun%20voor&options=mistlampen

    // TODO pewnie typy sa zle

    colors: string[] // &colors=
    doors: string[] //&doors=
    guarantee: string[]// &guarantee=
    nap: string[] // &nap=
    btw: string[] // &btw=

    advertiser_id: string

    orderBy: string //&sortItems=
    sortBy: "ASC" | "DESC"// &offset=0
    //isFormQuery: boolean    //&isFormQuery=true:
    offset: any | undefined,
    next: any// &next=
    /*    nst query = {
        brand: chooseValue(0, 'placeholder'),
        model: chooseValue(1, 'placeholder'),
        year_of_production_min: chooseValue(2, 'textContent', 'value'),
        year_of_production_max: chooseValue(3, 'textContent', 'value'),
        price_excluding_vat_min: chooseValue(4, 'textContent', 'value'),
        price_excluding_vat_max: chooseValue(5, 'textContent', 'value'),
        fuel: chooseValue(6, 'placeholder'),
        km_travelled_min: chooseValue(7, 'textContent', 'value'),
        km_travelled_max: chooseValue(8, 'textContent', 'value'),
        transmission: chooseValue(9, 'placeholder'),
        type: chooseValue(10, 'placeholder'),
        label: includeEnergyLabel.length === 0 ? null : includeEnergyLabel,
        description: chooseValue(19, 'value'),
        cylinder_capacity_min: chooseValue(20, 'textContent', 'value'),
        cylinder_capacity_max: chooseValue(21, 'textContent', 'value'),
        amount_capacity_min: chooseValue(22, 'textContent', 'value'),
        amount_capacity_max: chooseValue(23, 'textContent', 'value'),
        postcode: chooseValue(24, 'placeholder'),
        radius: chooseValue(25, 'placeholder'),
        days: chooseValue(26, 'placeholder'),
        car_dealer: chooseValue(27, 'placeholder'),
        power_min: chooseValue(28, 'textContent', 'value'),
        power_max: chooseValue(29, 'textContent', 'value'),
        seats_min: chooseValue(30, 'textContent', 'value'),
        seats_max: chooseValue(31, 'textContent', 'value'),
        weight_min: chooseValue(32, 'textContent', 'value'),
        weight_max: chooseValue(33, 'textContent', 'value'),
        options: includeOptions.length === 0 ? null : includeOptions,
        colors: includeColors.length === 0 ? null : includeColors,
        doors: includeDoors.length === 0 ? null : includeDoors,
        guarantee: includeGuarantee.length === 0 ? null : includeGuarantee,
        nap: includeNap.length === 0 ? null : includeNap,
        btw: includeBtw.length === 0 ? null : includeBtw,
        sortItems: {
            orderBy: '',
            sortBy: '',
            titleDropdown: 'Relevantie',
        },
        offset: '0',
        isFormQuery: true,
        next: amountCarPage,*/
}

export function getBaseCount(q: QueryDto): number {
    let count = q.brand != null && q.brand.length > 0 ? 1 : 0;
    count += q.fuel != null && q.fuel.length > 0 ? 1 : 0;
    count += (q.km_travelled_min != null || q.km_travelled_max != null) ? 1 : 0;
    count += (q.label || []).length > 0 ? 1 : 0
    count += q.model != null && q.model.length > 0 ? 1 : 0;
    count += (q.price_excluding_vat_min != null || q.price_excluding_vat_max != null) ? 1 : 0;
    count += q.transmission != null && q.transmission.length > 0 ? 1 : 0;
    count += q.type != null && q.type.length > 0 ? 1 : 0;
    count += (q.year_of_production_min != null || q.year_of_production_max != null) ? 1 : 0;
    count += (q.energyCounting != null || q.energyCounting != null) ? 1 : 0;

    return count;
}

export function getAdditionalCount(q: QueryDto): number {

    let count = q.description != null && q.description.length > 0 ? 1 : 0;

    count += (q.cylinder_capacity_min != null || q.cylinder_capacity_max != null) ? 1 : 0;
    count += (q.amount_capacity_min != null || q.amount_capacity_max != null) ? 1 : 0;
    count += (q.amount_cylinders_min != null || q.amount_cylinders_max != null) ? 1 : 0;
    count += (q.radius != null || q.postcode != null) ? 1 : 0;
    count += (q.days != null) ? 1 : 0;

    count += (q.car_dealer != null) ? 1 : 0;
    count += (q.power_min != null || q.power_max != null) ? 1 : 0;
    count += (q.seats_min != null || q.seats_max != null) ? 1 : 0;
    count += (q.weight_min != null || q.weight_max != null) ? 1 : 0;

    ensureArrayQueries(q)
    count += (q.options || []).length
        + (q.colors || []).length
        + (q.doors || []).length
        + (q.guarantee || []).length
        + (q.nap || []).length
        + (q.btw || []).length;
    return count;
}

export function isQueryEmpty(query: any): boolean {
    return Object.keys(query).length === 0;
}

function ensureArrayQueries(query: any) {
    if (query.label !== undefined && query.label !== null) {
        query.label = [].concat(query.label)
    }

    if (query.options !== undefined && query.options !== null) {
        query.options = [].concat(query.options)
    }
    if (query.colors !== undefined && query.colors !== null) {
        query.colors = [].concat(query.colors)
    }
    if (query.doors !== undefined && query.doors !== null) {
        query.doors = [].concat(query.doors)
    }
    if (query.guarantee !== undefined && query.guarantee != null) {
        query.guarantee = [].concat(query.guarantee)
    }
    if (query.nap !== undefined && query.nap !== null) {
        query.nap = [].concat(query.nap)
    }
    if (query.btw !== undefined && query.btw !== null) {
        query.btw = [].concat(query.btw)
    }
}

export function cleanupQuery(q: QueryDto) {
    const query = (q || {}) as any;
    if (query.offset === '0'|| query.offset === 0)
        query.offset = undefined
    if (query.next === '20' || query.next === 20)
        query.next = undefined;

    query.isFormQuery = undefined;
    if (query.power_min === "Min." || query.power_min === "Max.")
        query.power_min = undefined;
    if (query.power_max === "Min." || query.power_max === "Max.")
        query.power_max = undefined;
    if (query.amount_capacity_min === "Min." || query.amount_capacity_min === "Max.")
        query.amount_capacity_min = undefined;
    if (query.amount_capacity_max === "Min." || query.amount_capacity_max === "Max.")
        query.amount_capacity_max = undefined;
    if (query.amount_cilinders_min === "Min." || query.amount_cilinders_min === "Max.")
        query.amount_cilinders_min = undefined;
    if (query.amount_cilinders_max === "Min." || query.amount_cilinders_max === "Max.")
        query.amount_cilinders_max = undefined;
    if (query.cylinder_capacity_min === "Min." || query.cylinder_capacity_min === "Max.")
        query.cylinder_capacity_min = undefined;
    if (query.cylinder_capacity_max === "Min." || query.cylinder_capacity_max === "Max.")
        query.cylinder_capacity_max = undefined;
    if (query.year_of_production_min === "Min." || query.year_of_production_min === "Max.")
        query.year_of_production_min = undefined;
    if (query.year_of_production_max === "Min." || query.year_of_production_max === "Max.")
        query.year_of_production_max = undefined;
    if (query.km_travelled_min === "Min." || query.km_travelled_min === "Max.")
        query.km_travelled_min = undefined;
    if (query.km_travelled_max === "Min." || query.km_travelled_max === "Max.")
        query.km_travelled_max = undefined;
    if (query.price_excluding_vat_min === "Min." || query.price_excluding_vat_min === "Max.")
        query.price_excluding_vat_min = undefined;
    if (query.price_excluding_vat_max === "Min." || query.price_excluding_vat_max === "Max.")
        query.price_excluding_vat_max = undefined;
    if (query.seats_min === "Min." || query.seats_min === "Max.")
        query.seats_min = undefined;
    if (query.seats_max === "Min." || query.seats_max === "Max.")
        query.seats_max = undefined;
    if (query.transmission === 'Transmissie...') {
        query.transmission = undefined;
    }
    if (query.type === 'Carrosserie...') {
        query.type = undefined;
    }
    if (query.model === 'Model...') {
        query.model = undefined;
    }
    if (query.car_dealer === 'Geen voorkeur') {
        query.car_dealer = undefined;
    }

    if (query.days === 'Geen voorkeur') {
        query.days = undefined;
    }
    if (query.fuel === 'Brandstof...') {
        query.fuel = undefined;
    }
    if (query.radius === 'Straal...') {
        query.radius = undefined;
    }
    if (query.postcode === 'Postcode') {
        query.postcode = undefined;
    }
    if (query.brand === 'Merk...') {
        query.brand = undefined;
    }
    if (query.energyCounting === 'Bijtelling...') {
        query.energyCounting = undefined;
    }
    if (query.description === '') {
        query.description = undefined;
    }
    // make sure it is array
    ensureArrayQueries(query)


    /* if (dataObj.sortItems && dataObj.sortItems.orderBy==='') {
            // @ts-ignore
            dataObj.sortItems.orderBy=undefined;
        }
        if (dataObj.sortItems && dataObj.sortItems.sortBy==='') {
            // @ts-ignore
            dataObj.sortItems.sortBy=undefined;
        }
        if (dataObj.sortItems && dataObj.sortItems.titleDropdown==='Relevantie') {
            // @ts-ignore
            dataObj.sortItems.titleDropdown=undefined;
        }*/
    deleteUndefinedFields(query);
    return query;
}