import qs from 'qs';
import React, {useRef, useState} from 'react';
import {getSessionStorageProperty, setValuesSessionStorage} from '../../util/Session';
import {FormList} from "../home/searchForm/FormList";
import {useHistory} from "react-router-dom";
import TableListContainer from "./TableListContainer";
import Footer from "../../layout/Footer";
import {cleanupQuery, isQueryEmpty, QueryDto} from "../../util/RestQueries";
import {deleteUndefinedFields, pushNewSearch} from "../../util/reactUtils";
import {safeScroll, safeScrollTop} from "../../util/domainUtils";
import {isMobile} from "react-device-detect";

export const ListPage = () => {
    const history = useHistory();

    const parsed = cleanupQuery({...qs.parse(history.location.search, {ignoreQueryPrefix: true})} as any);
    const query = isQueryEmpty(parsed) ? getSessionStorageProperty("queryDto") || {} : parsed as QueryDto;

    const [isFormVisible, setIsFormVisible] = useState(false);
    // const [classFormProperty, setClassFormProperty] = useState('d-none d-md-block');
    const [queryOfResults, setQueryOfResults] = useState({...query} as QueryDto); // query in resultsTable
    const [formQuery, setFormQuery] = useState({...query} as QueryDto); // query in Form
    const resultsStatRef = useRef<HTMLDivElement>(null)


    // console.log("load list...");


    const setIsVisibleForm = () => {
        setIsFormVisible(!isFormVisible);
        // if(isFormVisible) {
        //     setClassFormProperty('');
        // }
        // if(!isFormVisible) {
        //     setClassFormProperty('d-none d-md-block');
        // }
    };



    const submitQuery = (query: QueryDto, scroll = true) => {
        query.offset = undefined;

        deleteUndefinedFields(query);
        const queryCopy = {...query}
        setValuesSessionStorage("queryDto", queryCopy);

        // console.log("push new search...", queryCopy);
        setFormQuery({...query});
        setQueryOfResults(queryCopy);

        if (scroll)
            safeScroll(resultsStatRef, "MOBILE")
        pushNewSearch(history, queryCopy);

    }

    const submitQueryAndScrollToResultsTop = (query: QueryDto) => {
        // safeScrollTop("ALL")
        if (isMobile)
            safeScroll(resultsStatRef, "MOBILE")
        else safeScrollTop("ALL")
        submitQuery(query, false)
    }

    const updateSortingAndMaxRowsOnResultsTable = (input: QueryDto) => {
        // console.log("update sorting", input);
        input = cleanupQuery(input);
        const rewriteFields = (q: QueryDto) => {
            q.orderBy = input.orderBy;
            q.sortBy = input.sortBy;
            q.next = input.next;
        }

        // update existing queries
        rewriteFields(formQuery);
        rewriteFields(queryOfResults);
        const queryFromDb = getSessionStorageProperty("queryDto") || {};
        rewriteFields(queryFromDb);
        setValuesSessionStorage("queryDto", queryFromDb);

        queryOfResults.offset = undefined;
        setQueryOfResults({...queryOfResults});
        pushNewSearch(history, queryOfResults);
    }
    const updatePageOnResultsTable = (obj: QueryDto) => {
        queryOfResults.offset = obj.offset;
        cleanupQuery(queryOfResults);
        setQueryOfResults({...queryOfResults})
        pushNewSearch(history, queryOfResults);
        safeScroll(resultsStatRef, "ALL")
    }


    return (
        <>
            <section
                className="d-none d-sm-none d-md-none d-lg-none d-xl-block position-relative background-form-list border-top border-bottom border-secondary">
            </section>
            <label
                className="label-form-list-extended d-block d-md-none bg-success text-center text-white font-weight-bold p-1 m-0 d-flex justify-content-center"
            >
                <input type="checkbox" onClick={setIsVisibleForm}/>
                <span className="text-form-list-extended font-weight-bold cursor-pointer d-flex align-items-center">
                        Wijzig zoekopdracht
                    </span>
            </label>
            <main className="list-page-wrapper">
                <section className="wrapper main p-0">
                    <section className={isFormVisible ? '' : 'd-none d-md-block'}>
                        <FormList
                            submitQuery={submitQuery}
                            formQuery={formQuery}
                            setFormQuery={setFormQuery}
                        />
                    </section>
                    <TableListContainer
                        updateQueriesSortingAndPaging={updateSortingAndMaxRowsOnResultsTable}
                        updatePageOnResultsTable={updatePageOnResultsTable}
                        queryDto={queryOfResults}
                        setLoadData={submitQueryAndScrollToResultsTop}
                        resultsStatRef={resultsStatRef}
                    />


                </section>
            </main>
            <Footer handleClick={(title: string) => {
                submitQuery({
                    brand: title,
                } as QueryDto);
            }}/>
        </>
    );
};
