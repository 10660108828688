import React from 'react';
import Spinner from "react-bootstrap/Spinner";
import {QueryRenderer} from "../list/QueryRenderer";
import {fetchCarDetails} from "../../util/RestQueries";
import {CarPageInternal} from "./CarPageInternal";


export const CarPage = (props: any) => {
    const {match} = props;
    let {id} = match.params;
    return <>
        <QueryRenderer fetchResponse={() => fetchCarDetails(id)}
                       query="A"
                       renderOnOK={response => {
                           return <CarPageInternal advertiser={response.data.advertiser}></CarPageInternal>
                       }}
                       renderOnError={<div className="w-100 text-center" style={{padding: "40vh", color: "red"}}>Advertentie-zoekvraag bestaat niet</div>}
                       renderOnStart={<div className="w-100 text-center mt-5 mb-5"><Spinner animation="border"/></div>}
        />
    </>;
}



