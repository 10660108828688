import React, {useState} from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
// import Dropdown from "react-bootstrap/Dropdown";
// import DropdownButton from "react-bootstrap/DropdownButton";
import {ModalThanks} from "../../layout/ModalThanks";
import ReCAPTCHA from "react-google-recaptcha";
import {CarProviderContactDto, sendContactEmailProvider} from "../../util/RestQueries";

export const FormCar = (props: { advertisement_id: any }) => {
    const [showThanks, setShowThanks] = useState(false);
    const [validated, setValidated] = useState(false);
    // const [dropdownCarTitle, setDropdownCarTitle] = useState('Selecteer hier het onderwerp');
    // const [dropdownCarAccessible, setDropdownCarAccessible] = useState('Is het voertuig nog beschikbaar?');
    const [recaptchaResponse, setRecaptchaResponse] = useState(null);

    let sendData = function (event: any) {
        const contact: any = {}
        const elements = [...event.target.elements] // map from html collection to array
        // console.log(elements);
        elements.forEach(elem => {
            if (elem.id)
                contact[elem.id] = elem.value;
            else if (elem.name) {
                contact[elem.name] = elem.checked;
            }
        })
        const c = contact as CarProviderContactDto;
        c.car_id = props.advertisement_id;

        sendContactEmailProvider(c)
            .then(e => setShowThanks(true));
    };

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() && recaptchaResponse) {
            sendData(event);
        }
        setValidated(true);
    };

    const closeModalContact = () => {
        setShowThanks(false);
        window.location.reload();
    };

    const handleCaptchaResponseChange = (response: any) => {
        setRecaptchaResponse("recaptcha" as any)
    };

    return (
        <Form className="form mt-3 p-3" noValidate validated={validated} onSubmit={handleSubmit}>
            {/* <Dropdown>
                <Dropdown.Toggle
                    required
                    id="dropdown-form-car"
                    title={dropdownCarTitle}
                    className="dropdown-min-max mb-3 p-2 col-12 d-flex justify-content-between text-secondary bg-white shadow-none"
                >
                    {dropdownCarTitle}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setDropdownCarTitle('1...')}>
                        {'1...'}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setDropdownCarTitle('1...')}>
                        {'2...'}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown> */}
            <Form.Group className="bg-white p-2" controlId="form-car-accessible">
                <div>
                    <label className="checkbox-wrapper text-secondary m-0">
                        {'Ik wil graag een proefrit maken'}
                        <input type="checkbox" name="option_take_a_test_drive"/>
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div>
                    <label className="checkbox-wrapper text-secondary m-0">
                        {'Ik wil mijn huidige auto inruilen'}
                        <input type="checkbox" name="option_trade_in_my_current_car"/>
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div>
                    <label className="checkbox-wrapper text-secondary m-0">
                        {'Ik wil graag teruggebeld worden'}
                        <input type="checkbox" name="option_call_me_back"/>
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div>
                    <label className="checkbox-wrapper text-secondary m-0">
                        {'Ik ben geïnteresseerd in financieren'}
                        <input type="checkbox" name="option_interested_in_financing"/>
                        <span className="checkmark"></span>
                    </label>
                </div>
            </Form.Group>
            <Form.Group controlId="sender_email" className="border-0">
                <Form.Control
                    required
                    type="email"
                    placeholder="E-mailadres"
                    className="rounded-0 border-0"
                />
                <Form.Control.Feedback>Ziet er goed uit!</Form.Control.Feedback>
            </Form.Group>
            <Form.Row>
                <Form.Group as={Col} md="6" controlId="sender_name">
                    <Form.Control
                        required
                        type="text"
                        placeholder="Naam"
                        className="rounded-0 border-0"
                    />
                    <Form.Control.Feedback>Ziet er goed uit!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="sender_phone_number">
                    <Form.Control
                        required
                        type="tel"
                        pattern="\d+"
                        placeholder="Telefoonnummer"
                        className="rounded-0 border-0"
                    />
                    <Form.Control.Feedback>Ziet er goed uit!</Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
            <Form.Group controlId="message">
                <Form.Label>Bericht</Form.Label>
                <Form.Control
                    required
                    as="textarea"
                    rows={3}
                    className="rounded-0 border-0"
                />
                <Form.Control.Feedback>Ziet er goed uit!</Form.Control.Feedback>
            </Form.Group>
            <Form.Row>
                <section className="col-12 col-md-6 ">
                    <ReCAPTCHA
                        sitekey="6LflbNUZAAAAACX7ioe4VcZPvF55RY3pTLmH7s54"
                        onChange={handleCaptchaResponseChange}
                    />
                </section>
                <section
                    className="col-12 col-md-6 text-center text-md-right d-flex align-items-end justify-content-end pt-3">
                    <Button className="col-12 col-md-6 bg-success rounded-0 border-0" type="submit">
                        Verstuur bericht
                    </Button>
                    <ModalThanks
                        show={showThanks}
                        onHide={() => closeModalContact()}
                    />
                </section>
            </Form.Row>
        </Form>
    );
};
