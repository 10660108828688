import * as React from "react";
import {BannerDto, fetchBanners} from "../../util/RestQueries";

let groped_banners_cache: any | null = null;

function groupByKey(array: any, key: any) {
    return array
        .reduce((hash: any, obj: any) => {
            if (obj[key] === undefined) return hash;
            return Object.assign(hash, {[obj[key]]: (hash[obj[key]] || []).concat(obj)})
        }, {})
}

export class Banners {
    groped_banners_cache: { [index: string]: BannerDto[] };
    current_iter: number;

    constructor(groped_banners_cache: any, current_iter: number = 0) {
        this.groped_banners_cache = groped_banners_cache;
        this.current_iter = current_iter;
    }

    findBanner = (bannerId: string, randomOffset: number = 0): BannerDto | null => {
        const bannerList = this.groped_banners_cache[bannerId] || [];
        if (bannerList.length === 0)
            return null;
        return bannerList[(this.current_iter + randomOffset) % bannerList.length];
    }


}

export const callForBanners = function (setBanners: (banners: Banners) => void) {
    let timerId = -1 as any;
    const fetchData = async () => {
        if (groped_banners_cache == null) {
            const incoming = await fetchBanners() || [];
            incoming.forEach(b => {
                if (b.rotation_order === undefined)
                    b.rotation_order = 0;
            })
            incoming.sort((a, b) => a.rotation_order === b.rotation_order
                ? 0
                : +(a.rotation_order > b.rotation_order) || -1)

            groped_banners_cache = groupByKey(incoming, 'banner_id');

        }

        // const keys = Object.keys(groped_banners_cache)
        let currentIter = 0;
        const setBannersForIter = () => {
            /*       const res = [] as BannerDto[];
                   keys.forEach(key => {
                       const bannerIdArr = groped_banners_cache[key];
                       const bannerToSelect = bannerIdArr[currentIter % bannerIdArr.length];
                       res.push(bannerToSelect);
                   })*/
            setBanners(new Banners(groped_banners_cache, currentIter));
            currentIter++;
        }
        setBannersForIter();
        timerId = setInterval(() => {
            setBannersForIter();
        }, 10000);


    };
    fetchData();
    return () => {
        if (timerId !== -1)
            clearInterval(timerId);
    };
};

export const Banner = (props: { banner_id: string, width?: number, height?: number, banners: Banners, className?: string, offset?: number, imgClassName?: string }) => {
    const banner = props.banners.findBanner(props.banner_id, props.offset || 0)


    const printBanner = (bannerDto: BannerDto) => {
        return <div style={{width: props.width, height: props.height}} className={props.className}>
            <a href={bannerDto.url} target="_blank" rel="noreferrer">
                <img className={props.imgClassName} src={bannerDto.img_upload ? bannerDto.img_upload : bannerDto.img!} alt={bannerDto.alt}/>
            </a>
        </div>;
    }

    if (banner === null) {
        return <div
            style={{width: props.width, height: props.height, backgroundColor: "#dddddd", fontSize: 8, color: "black"}}>
            {props.banner_id}
        </div>;
    } else {
        return printBanner(banner);
    }
}
