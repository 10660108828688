import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import {useHistory} from "react-router-dom";
import {formatPrice, safeScrollTop} from "../../util/domainUtils";


export const CarouselHome = (props: any) => {
  const history = useHistory();
  const [index, setIndex] = useState(0);
  // const [direction, setDirection] = useState(null);
  const { carouselItems } = props;
  
  const handleSelect = (selectedIndex: number, e: any) => {
    setIndex(selectedIndex);
    // setDirection(e.direction);
  };

  const picturesShow = 12;
  const createSlides = () => {
    let slides = [];
    for (let i = 0; i < carouselItems.length; i = i + picturesShow) {
        slides.push(carouselItems.slice(i, i + picturesShow));
    }
    return slides;
  }

  const slides = createSlides();

  const openCarPage = (id: any) => {
    safeScrollTop("ALL");
    history.push({   pathname: '/car/'+id })

   /* Router.push({
      pathname: '/car',
      query: { id: id },
    });*/
  };

  const pictures = slides.map((pictureArr: any, slidesIndex: number) => {
    return (
      <Carousel.Item key={slidesIndex}>
        <Row className="p-0 m-0 list-advertiser">
          {pictureArr.map((elem: any, pictureIndex: number) => {
            return (
              <section key={elem.id + pictureIndex}>
                <Nav.Link
                  className="nav-link text-dark p-0"
                  onClick={() => openCarPage(elem.advertisement.id)}/* was: elem.hexon_id*/
                  href="#"
                >
                  <div className="container-fluid img-card-carousel p-0 position-relative">
                    <img src={elem.advertisement.feature_image} className="w-100" alt="car" />
                    <span className="letter-card-Item">
                      <div
                        className={`category-form text-center category-letter-${elem.specification.energy_label} text-white`}
                      >
                        {elem.specification.energy_label}
                      </div>
                    </span>
                  </div>
                  <Row className="p-0 m-0">
                    <p>
                      <small className="font-weight-bold">
                        {elem.advertisement.brand.length > 8
                          ? `${elem.advertisement.brand.slice(0, 8)}...`
                          : elem.advertisement.brand}
                      </small>
                    </p>
                    <p className="ml-auto">
                      <small className="font-weight-bold">
                        &euro; {formatPrice(elem.advertisement.pricing[0].price_excluding_vat)}
                      </small>
                    </p>
                  </Row>
                </Nav.Link>
              </section>
            )
          })}
        </Row>
      </Carousel.Item>
    )
  });

  return (
    <Carousel
      className="pt-2 carousel-home-page"
      activeIndex={index}
      indicators={false}
      keyboard={false}
      controls={true}

      onSelect={handleSelect}
    >
      {pictures}
    </Carousel>
  );
};
