import React from 'react';
import Footer from "./Footer";
import {useHistory} from "react-router-dom";
import {pushNewSearch} from "../util/reactUtils";
import {QueryDto} from "../util/RestQueries";
import {safeScrollTop} from "../util/domainUtils";

export const FooterContainer = () => {
    const history = useHistory();

    const handleClick = (title: string) => {
        safeScrollTop("ALL");
        pushNewSearch(history, {brand: title} as QueryDto)
    };

    return <Footer handleClick={handleClick}/>
};
