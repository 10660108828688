import React, {MutableRefObject, useEffect, useState} from 'react';
import Spinner from "react-bootstrap/Spinner";
import {getSessionStorageProperty, setValuesSessionStorage} from '../../util/Session';
import {jsonToQueryString, performQuery} from "../../util/RestQueries";
import {QueryRenderer} from "./QueryRenderer";
import {TableList} from "./TableList";
import {Banners, callForBanners} from "../home/Banner";


const TableListContainer = (props: {
    updateQueriesSortingAndPaging: any,
    updatePageOnResultsTable: any,
    setLoadData: any,
    queryDto: any,
    resultsStatRef: MutableRefObject<any>
}) => {
    const {
        updateQueriesSortingAndPaging,
        updatePageOnResultsTable,
        queryDto,
        setLoadData,
        resultsStatRef
    } = props;

    const [amountCarPage, setAmountCarPage] = useState(queryDto.next || getSessionStorageProperty('amountCarPage') || '20');
    const [orderBy, setOrderBy] = useState(queryDto.orderBy || getSessionStorageProperty('orderBy') || undefined);
    const [sortBy, setSortBy] = useState(queryDto.sortBy || getSessionStorageProperty('sortBy') || undefined);
    const [banners, setBanners] = useState(new Banners([]));
    useEffect(() => {
        return callForBanners(setBanners);
    }, []);

    const sortList = (event: string) => {
        const desc = 'DESC';
        const asc = 'ASC';
        const price_excluding_vat = 'price_excluding_vat';
        const km_travelled = 'km_travelled';
        const year_of_production = 'year_of_production';

        let _orderBy = undefined;
        let _sortBy = undefined;

        switch (event) {
            case 'Prijs laag naar hoog':
                _orderBy = price_excluding_vat;
                _sortBy = asc;
                break;
            case 'Prijs hoog naar laag':
                _orderBy = price_excluding_vat;
                _sortBy = desc;
                break;
            case 'Km stand laag naar hoog':
                _orderBy = km_travelled;
                _sortBy = asc;
                break;
            case 'Km stand hoog naar laag':
                _orderBy = km_travelled;
                _sortBy = desc;
                break;
            case 'Bouwjaar oud naar nieuw':
                _orderBy = year_of_production;
                _sortBy = asc;
                break;
            case 'Bouwjaar nieuw naar oud':
                _orderBy = year_of_production;
                _sortBy = desc;
                break;

            default:
                break;
        }

        setValuesSessionStorage('orderBy', _orderBy);
        setValuesSessionStorage('sortBy', _sortBy);
        setOrderBy(_orderBy);
        setSortBy(_sortBy);
        updateQueriesSortingAndPaging({
            ...queryDto,
            offset: 0,
            orderBy: _orderBy,
            sortBy: _sortBy
        });
    };

    const setAmountCarPerPage = (value: any) => {
        setValuesSessionStorage('amountCarPage', value);
        setAmountCarPage(value);
        if (value === "20") {
            value = undefined;
        }
        updateQueriesSortingAndPaging({...queryDto, next: value});
    }

    let startRow = +(queryDto.offset || 0);
    const getPages = (value: any, numberOfItems: any) => {
        const lastSearchedQuery = queryDto;//getSessionStorageProperty("lastSearchedQueryDto", {} as any);
        if (!Number.isInteger(startRow)) {
            startRow = 0;
        }
        if (value === 'prev' && queryDto.offset >= +amountCarPage) {
            startRow -= +amountCarPage;
            return updatePageOnResultsTable({...lastSearchedQuery, offset: startRow,});
        }
        if (value === 'next' && ((queryDto.offset | 0) < numberOfItems - +amountCarPage)) {
            startRow += +amountCarPage;
            return updatePageOnResultsTable({...lastSearchedQuery, offset: +startRow});
        }
        if (Number.isInteger(value)) {
            startRow = value;
            return updatePageOnResultsTable({...lastSearchedQuery, offset: value * +amountCarPage,});
        }
    };

    return (

        <QueryRenderer fetchResponse={() => {
            console.log("perform query request...", queryDto);
            return performQuery(queryDto, true)
        }}
                       query={jsonToQueryString(queryDto)}
                       renderOnOK={response => {
                           return <TableList
                               advertiser={response.data.advertisers}
                               sortList={sortList}
                               getPages={getPages}
                               brand={queryDto.brand}
                               dataQuery={queryDto}
                               setLoadData={setLoadData}
                               offset={queryDto.offset}
                               orderBy={orderBy}
                               sortBy={sortBy}
                               amountCarPage={amountCarPage}
                               setAmountCarPerPage={setAmountCarPerPage}
                               banners={banners}
                               resultsStatRef={resultsStatRef}
                           />
                       }}
                       renderOnStart={<div className="text-center p-5 m-5"><Spinner animation="border"/></div>}
                       renderOnError={<div className="w-100 text-center" style={{padding: "40vh", color: "red"}}>Interne
                           Server Fout</div>}
        />

    )
};

export default TableListContainer;



