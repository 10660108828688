import React, {useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {
    btw,
    colorsFirstColumn,
    colorsSecondColumn,
    colorsThirdColumn,
    doorsColumn,
    guarantee,
    nap,
    optionsFirstColumn,
    optionsFourthColumn,
    optionsSecondColumn,
    optionsThirdColumn,
} from "../../../../Constants";
import {QueryDto} from "../../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../../apis";
import {withUniqueAdded, withUniqueRemoved} from "../../../../util/reactUtils";

export const CheckboxesContainer = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;
    const {
        clearForm,
        setClearForm,
    } = props;
    const [lastChangedArray, _setLastChangedArray] = useState(1);

    const handleChange = (event: any, fieldName: string, label: string) => {

        if (event.target.checked) {
            (queryDto as any)[fieldName] = withUniqueAdded((queryDto as any)[fieldName], label);
        } else {
            (queryDto as any)[fieldName] = withUniqueRemoved((queryDto as any)[fieldName], label) as any;
        }

        _setLastChangedArray(lastChangedArray + 1);
        //_setLabel(queryDto.label);
        saveQuery(fieldName, (queryDto as any)[fieldName]);


        setClearForm(false);

    }
    const isChecked = (fieldName: string, label: string): boolean => {
        let labels = (queryDto as any)[fieldName] || [];
        if (labels != null) {
            if (!Array.isArray(labels)) {
                labels = [labels]
            }
            return labels.some((lab: any) => lab === label);
        }
        return false;
    }

    if (clearForm) {
        queryDto.options = undefined as any;
        queryDto.colors = undefined as any;
        queryDto.doors = undefined as any;
        queryDto.guarantee = undefined as any;
        queryDto.nap = undefined as any;
        queryDto.btw = undefined as any;
    }


    const creatCheckboxItems = (arr: any[], fieldName: any, fields: any[]) => {
        return arr.map((checkboxItem: any, index: number) => {
            const {label, id, className} = checkboxItem
            return (
                <label key={index}
                       className={className ? className + ' checkbox-wrapper' : 'checkbox-wrapper'}>
                    {label ? label : id}
                    <input
                        type="checkbox"
                        name={id}
                        checked={isChecked(fieldName, id)} /*currentItemsCheckbox.some(item => item === id)}*/
                        onChange={event => handleChange(event, fieldName, id)} /*handleCheckedCheckboxes(event, fieldName)}*/
                    />
                    <span className="checkmark"/>
                </label>)
        })
    };

    return (
        <>
            <Row className="m-0 p-0">
                <Form.Label className="col-12 p-1 mb-0 mt-2 font-weight-bold">
                    Opties
                </Form.Label>
                <Form.Group className="col-6 col-md-3 pl-1 pr-1 m-0">
                    {creatCheckboxItems(optionsFirstColumn, "options", queryDto.options)}
                </Form.Group>
                <Form.Group className="col-6 col-md-3 pl-1 pr-1 m-0">
                    {creatCheckboxItems(optionsSecondColumn, "options", queryDto.options)}
                </Form.Group>
                <Form.Group className="col-6 col-md-3 pl-1 pr-1 m-0">
                    {creatCheckboxItems(optionsThirdColumn, "options", queryDto.options)}
                </Form.Group>
                <Form.Group className="col-6 col-md-3 pl-1 pr-1 m-0">
                    {creatCheckboxItems(optionsFourthColumn, "options", queryDto.options)}
                </Form.Group>
            </Row>
            <Row className="m-0 p-0  mt-2">
                <Row className="m-0 p-0 col-12 col-md-9">
                    <Form.Label className="col-12 p-1 mb-0 mt-2 font-weight-bold">
                        Kleuren
                    </Form.Label>
                    <Form.Group className="col-6 col-md-4 pl-1 pr-1 m-0">
                        {creatCheckboxItems(colorsFirstColumn, "colors", queryDto.colors)}
                    </Form.Group>
                    <Form.Group className="col-6 col-md-4 pl-1 pr-1 m-0">
                        {creatCheckboxItems(colorsSecondColumn, "colors", queryDto.colors)}
                    </Form.Group>
                    <Form.Group className="col-12 col-md-4 pl-1 pr-1 m-0">
                        <Row className="m-0 p-0">
                            {creatCheckboxItems(colorsThirdColumn, "colors", queryDto.colors)}
                        </Row>
                    </Form.Group>
                </Row>
                <Row className="m-0 p-0 col-12 col-md-3">
                    <Form.Label className="col-12 p-1 mb-0 mt-2 font-weight-bold">
                        Deuren
                    </Form.Label>
                    <Form.Group className="col-md-12 m-0 p-0">
                        <Row className="d-flex m-0 p-0">
                            {creatCheckboxItems(doorsColumn, "doors", queryDto.doors)}
                        </Row>
                    </Form.Group>
                </Row>
            </Row>
            <Row className="m-0 p-0">
                <Row className="col-12 col-md-3 m-0 p-0 mt-2">
                    <Form.Label className="col-12 p-1 mb-0 mt-2 font-weight-bold">
                        Garantie
                    </Form.Label>
                    <Form.Group className="col-12 p-1">
                        {creatCheckboxItems(guarantee, "guarantee", queryDto.guarantee)}
                    </Form.Group>
                </Row>
                <Row className="col-12 col-md-3 m-0 p-0 mt-2">
                    <Form.Label className="col-12 p-1 mb-0 mt-2 font-weight-bold">
                        NAP
                    </Form.Label>
                    <Form.Group className="col-12 p-1 mb-md-5">
                        {creatCheckboxItems(nap, "nap", queryDto.nap)}
                    </Form.Group>
                </Row>
                <Row className="col-12 col-md-6 m-0 p-0 mt-2">
                    <Form.Label className="col-12 p-1 mb-0 mt-2 font-weight-bold">
                        Btw
                    </Form.Label>
                    <Form.Group className="col-12 p-1 mb-md-5">
                        {creatCheckboxItems(btw, "btw", queryDto.btw)}
                    </Form.Group>
                </Row>
            </Row>
        </>
    )
}