import React from "react";
import fav from "../../images/fav.jpg";
import Navbar from "react-bootstrap/Navbar";

import {NavLink} from "react-router-dom";
import logo_300 from "./../../images/logo_300.png";


export const Header = () => {
    const titleLinks = [
        {
            href: '/home',
            title: 'Home',
        },
        {
            href: '/faq',
            title: 'Veelgestelde vragen',
        },
        {
            href: '/contact',
            title: 'Contact',
        }
    ];

    return (
        <header className="wrapper p-0">
            <Navbar expand="lg" className="pt-0 pb-0">
                <div className="container-fluid p-0" style={{minWidth: 300}}>
                    <Navbar.Brand href="/home">
                        <div className="navbar-brand p-0">
                                <img src={logo_300} className="my-auto" alt="logo"/>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <ul className="navbar-nav ml-auto font-weight-bold">
                            {titleLinks.map((item, index) => (
                                <li className="nav-item" key={index}>
                                    <NavLink to={item.href} activeClassName="link-active-header"
                                             className="nav-link text-dark position-relative">{item.title}</NavLink>
                                </li>)
                            )}
                        </ul>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </header>
    );
};

