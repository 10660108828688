import React from 'react';
import {CarouselHome} from "./CarouselHome";
// import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
// import inko from "../../../images/inkoopconcurrent_banner.jpg";
// import kenteken from "../../../images/kentekenverpanden.gif";
import {Banner} from "./Banner";
import {Link} from "react-router-dom";

export const MainHomePage = (props: any) => {
    return (
        <main className="col-12 col-md-10 mt-3">
            <h2 className="font-weight-bold">
                Zoek jouw zuinige auto op Rijzuinig.nl
            </h2>
            <p>Rijzuinig.nl adverteert naast gewone occasions ook in het bijzonder
                <b> zuinige occasions. </b>
                Dit zijn immers de occasions van de toekomst. Steeds meer mensen zijn
                bezig met een energie zuiniger en milieubewuster bestaan. Een van de grootste
                vervuilers van onze aarde zijn auto's. Met de komst van steeds meer energiezuinige
                auto's zijn wij een platform begonnen waarmee je naast "gewone" occasions, ook
                specifiek kunt zoeken op energiezuinige auto's. Dat is de kracht van Rijzuinig.nl
            </p>
            <h2 className="font-weight-bold">
                Bekijk onze nieuwste occasions
            </h2>
            <CarouselHome carouselItems={props.carouselItems}/>
            <Row className="m-0 p-0 mb-3">
                <div className="container col-12 col-md-6 p-0 pr-md-2 mt-3">
                    <section className="col-12 home-page-addition-info p-4">
                        <h2 className="font-weight-bold">
                            Adverteren?
                        </h2>
                        <p className="m-0">
                            Wilt u adverteren op rijzuinig.nl?<br />
                            Klik dan <Link className="text-dark font-weight-bold" to={'/contact'}>hier</Link>!

                        </p>

                    </section>
                </div>
                <div className="container col-12 col-md-6 p-0 pl-md-2 mt-3">
                    {/*<section className="col-12 home-page-addition-info p-4">*/}
                        {/*<h2 className="font-weight-bold">*/}
                        {/*    Kenteken verpanden?*/}
                        {/*</h2>*/}
                        {/*<p className="m-0">*/}
                        {/*    Verpand uw auto*/}
                        {/*</p>*/}
                        {/*<p className="m-0">*/}
                        {/*    Direct contact geld Betaal terug met gunstige rente*/}
                        {/*</p>*/}
                        {/*<p className="m-0">*/}
                        {/*    Leen wat je nodig hebt*/}
                        {/*</p>*/}
                        {/*<p className="m-0 d-inline-block pr-1">Ga naar:*/}
                        {/*</p>*/}
                        {/*<Nav.Link className="nav-link text-dark p-0 d-inline-block font-weight-bold" href="#">*/}
                        {/*    www.kentekenverpanden.nl*/}
                        {/*</Nav.Link>*/}
                    {/*</section>*/}
                </div>
            </Row>
            <section className="d-md-none col-12">

                <Row>

                  <section className="col-6 col-md-12 p-0 p-3">
                        <Banner banner_id={"HOME_160x600_A"}  banners={props.banners}/>
                    </section>
                    <section className="col-6 col-md-12 p-0 p-3">
                        <Banner banner_id={"HOME_160x600_B"} banners={props.banners}/>
                    </section>

                    {/*<section className="col-6 col-md-12  p-0 p-3">
                        <div>
                            <a href="https://inkoopconcurrent.nl/" target="_blank" rel="noreferrer">
                                <img src={inko} alt="inko"/>
                            </a>
                        </div>
                    </section>
                    <section className="col-6 col-md-12 p-0  p-3">
                        <div>
                            <a href="http://kentekenverpanden.nl/" target="_blank" rel="noreferrer">
                                <img src={kenteken} alt="kenteken"/>
                            </a>
                        </div>
                    </section>*/}
                </Row>
                {/*
                Commented & replaced with upper because of
                10) SMALL BUG > Banners on the homepage are not showing on mobile phone
                <Row>
                    <section className="col-6 col-md-12 p-0 pr-3">
                        <div className="container advertising-aside-home-page bg-secondary"></div>
                    </section>
                    <section className="col-6 col-md-12 p-0 pl-3">
                        <div className="container advertising-aside-home-page bg-secondary"></div>
                    </section>


                </Row>*/}
            </section>
            <Row className="col-12 m-0 p-0 pt-3">
                <section className="order-0 order-md-1 p-0 col-12 col-md-8 pl-md-3">
                    <h2 className="font-weight-bold">
                        Occasions zoeken op Rijzuinig.nl
                    </h2>
                    <p>Rijzuinig.nl adverteert naast gewone occasions ook in het bijzonder
                        <b> zuinige occasions. </b>
                        Dit zijn immers de occasions van de toekomst. Steeds meer mensen zijn
                        bezig met een energie zuiniger en milieubewuster bestaan. Een van de grootste
                        vervuilers van onze aarde zijn auto's. Met de komst van steeds meer energiezuinige
                        auto's zijn wij een platform begonnen waarmee je naast "gewone" occasions, ook
                        specifiek kunt zoeken op energiezuinige auto's. Dat is de kracht van Rijzuinig.nl
                    </p>
                    <p className="d-none d-md-block mt-auto mb-0">
                        Rijzuinig.nl adverteert naast gewone occasions ook in het bijzonder
                        <b> zuinige occasions. </b>
                        Dit zijn immers d...
                    </p>
                </section>
                <div
                    className="order-1 order-md-0 bg-secondary p-3 col-12 col-md-4 align-content-center justify-content-center">
                    <Banner banner_id={"HOME_300x250"} width={undefined} height={undefined} banners={props.banners}
                            imgClassName="img-home-page"/>

                </div>
                {/* <div className="order-1 order-md-0 bg-secondary p-3 col-12 col-md-4">
                    <img className="img-home-page" src={trustpilot} alt=""/>
                </div>*/}
            </Row>
        </main>
    )
};
