export const getSessionStorageProperty = <E>(key: string, defaultValueProvider?: ()=>E) => {
    if (typeof window !== 'undefined') {
         const fromSession=sessionStorage.getItem(key);
         if (fromSession!=null) {
             try {
                 return JSON.parse(fromSession)
             } catch (err) {
                 console.error("Cannot parse value for key "+key, fromSession);
                 if (fromSession==='undefined') {
                     console.log("removing undefined item "+key);
                     sessionStorage.removeItem(key);
                 }
                 //throw err;
             }
         }
    }
    if (defaultValueProvider!=null) {
        return defaultValueProvider();
    }
    return null;
}

export const setValuesSessionStorage = (name: string, value: any) => {
    if (value===undefined) {
        sessionStorage.removeItem(name);
    } else {
        sessionStorage.setItem(name, JSON.stringify(value));
    }
}
export const removeValuesSessionStorage = (value: string) => sessionStorage.removeItem(value);


