import React, { useState } from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DropdownMinMax } from '../dropdowns/DropdownMinMax';
import { itemsAmountCilinders } from "../../../../Constants";
import {QueryDto} from "../../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../../apis";


export const CylinderAmountContainer = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;
    const { addDropdownMax, addDropdownMin, countCheckboxDropdownMinMax, clearForm, setClearForm, checkVisibleForm } = props;

    const [amountCylindersMin, _setAmountCylindersMin] = useState(queryDto.amount_cylinders_min/*getSessionStorageProperty('amountCylindersMin')*/ || 'Min.');
    const [amountCylindersMax, _setAmountCylindersMax] = useState(queryDto.amount_cylinders_max/*getSessionStorageProperty('amountCylindersMax')*/ || 'Max.');

    const setAmountCylindersMin = (value: any) => {
        queryDto.amount_cylinders_min=value;
        saveQuery('amount_cylinders_min', value);
        _setAmountCylindersMin(value);
    }
    const setAmountCylindersMax = (value: any) => {
        queryDto.amount_cylinders_max=value;
        saveQuery('amount_cylinders_max', value);
        _setAmountCylindersMax(value);
    }


    if ((clearForm || queryDto.amount_cylinders_min===undefined) && amountCylindersMin !== 'Min.') {
        _setAmountCylindersMin('Min.');
    }

    if ((clearForm || queryDto.amount_cylinders_max===undefined) && amountCylindersMax !== 'Max.') {
        _setAmountCylindersMax('Max.');
    }

    return (
        <Form.Group
                    className="col-12 col-md-6 col-lg-3 p-1"
                    controlId="cylinder"
                >
                    <Form.Label className="font-weight-bold">
                        Aantal cilinders
                    </Form.Label>
                    <Row className="m-0">
                        <DropdownMinMax
                            id={'amountCylindersMin'}
                            idCompare={'amountCylindersMax'}
                           /* conditionShowItems={index => amountCylindersItems[index]}*/
                            arrayForItems={itemsAmountCilinders}
                            addDropdown={addDropdownMin}
                            setTitle={setAmountCylindersMin}
                            title={amountCylindersMin}
                            titleCompare={amountCylindersMax}
                            setTitleCompare={setAmountCylindersMax}
                            classProperties={"dropdown-min-max col-6 p-0 m-0"}
                            countCheckboxDropdownMinMax={countCheckboxDropdownMinMax}
                            initialValue={'min'}
                            setClearForm={setClearForm}
                            checkVisibleForm={checkVisibleForm}
                        />
                        <DropdownMinMax
                            id={'amountCylindersMax'}
                            idCompare={'amountCylindersMin'}
           /*                 conditionShowItems={index => amountCylindersItems[index]}*/
                            arrayForItems={itemsAmountCilinders}
                            addDropdown={addDropdownMax}
                            setTitle={setAmountCylindersMax}
                            title={amountCylindersMax}
                            titleCompare={amountCylindersMin}
                            setTitleCompare={setAmountCylindersMin}
                            classProperties={"dropdown-min-max col-6 p-0 m-0"}
                            countCheckboxDropdownMinMax={countCheckboxDropdownMinMax}
                            initialValue={'max'}
                            setClearForm={setClearForm}
                            checkVisibleForm={checkVisibleForm}
                        />
                    </Row>
                </Form.Group>
    )
}