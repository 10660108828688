import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import {DropdownFormInput} from "../dropdowns/DropdownFormInput";
import {QueryDto} from "../../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../../apis";

export const CarDealerContainer = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;
    const {getDropDownItems, countInputs, clearForm, setClearForm} = props;

    const privateCarDealerItems = ['Geen voorkeur', 'Autobedrijf', 'Particulier'];
    const [privateCarDealerPlaceholder, _setPrivateCarDealerPlaceholder] = useState(queryDto.car_dealer /*getSessionStorageProperty('privateCarDealerPlaceholder')*/ || 'Geen voorkeur');
    const [filteredPrivateCarDealerItems, setFilteredPrivateCarDealerItems] = useState(privateCarDealerItems);
    const [inputPrivateCarDealerValue, setInputPrivateCarDealerValue] = useState('');

    const setPrivateCarDealerPlaceholder = (value: any) => {
        queryDto.car_dealer = value;
        saveQuery('car_dealer', value);
        _setPrivateCarDealerPlaceholder(value);
    }

    if ((clearForm || queryDto.car_dealer === undefined) && privateCarDealerPlaceholder !== 'Geen voorkeur') {
        _setPrivateCarDealerPlaceholder('Geen voorkeur');
    }

    return (
        <Form.Group
            className="col-12 col-md-6 col-lg-3 p-1"
            controlId="private-carDealer"
        >
            <Form.Label className="font-weight-bold">
                Autobedrijf / Particulier
            </Form.Label>
            <DropdownFormInput
                filteredItems={filteredPrivateCarDealerItems}
                getDropDownItems={getDropDownItems}
                placeholder={privateCarDealerPlaceholder}
                inputValue={inputPrivateCarDealerValue}
                value={'privateCarDealer'}
                countInputs={countInputs}
                setPlaceholder={setPrivateCarDealerPlaceholder}
                setInputValue={setInputPrivateCarDealerValue}
                setFilteredItems={setFilteredPrivateCarDealerItems}
                dropDownItems={privateCarDealerItems}
                setClearForm={setClearForm}
                checkVisibleForm={props.checkVisibleForm}
            />
        </Form.Group>
    )
}