import React from 'react';
import {Header} from "./components/layout/Header";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import './styles/main.scss';

import ContactPage from "./components/pages/contact/ContactPage";
import {HomePage} from "./components/pages/home/HomePage";
import {FaqPage} from "./components/pages/faq/FaqPage";
import {FooterContainer} from "./components/layout/FooterContainer";
import {ListPage} from "./components/pages/list/ListPage";
import {CarPage} from "./components/pages/car/CarPage";
import CookieConsent from "react-cookie-consent";




function App() {

    return (
        <BrowserRouter>
            <Header/>
            <div>
                <Route path="/" exact={true}>
                    <HomePage/>
                </Route>
                <Route path="/home">
                    <HomePage/>
                </Route>
                <Route path="/list">
                    <ListPage/>
                </Route>
                <Route path="/faq">
                    <FaqPage/>
                </Route>
                <Route path="/contact">
                    <ContactPage/>
                </Route>
                <Route path="/car/:id" component={CarPage} />

            </div>

            <Switch>

                <Route path="/list" component={()=><></>}/>
                <Route component={ FooterContainer} />
            </Switch>

            {/*<CookieConsent*/}
            {/*    location="bottom"*/}
            {/*    cookieName="consentCookie"*/}
            {/*    style={{ background: "#2B373B" }}*/}
            {/*    buttonClasses="btn btn-primary"*/}
            {/*    buttonStyle={{ backgroundColor: "#28a745",  color: "white", fontSize: "13px" }}*/}
            {/*    buttonText="Ik begrijp het"*/}
            {/*    expires={150}*/}
            {/*>*/}

            {/*    <span style={{ fontSize: "12px" }}>*/}
            {/*        Deze website maakt gebruik van cookies om de gebruikerservaring te verbeteren.*/}
            {/*        Rijzuinig.nl en haar partners verzamelen met technieken waaronder cookies meer informatie over je apparaat, locatie, browser en surfgedrag.</span>*/}
            {/*</CookieConsent>*/}
        </BrowserRouter>
    );
}

export default App;
