import React from 'react';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {useHistory} from "react-router-dom";

//import {getSessionStorageProperty} from "../Session";

export const Navigation = (props: any) => {
    const history = useHistory();

    const handleClick = (value: string) => {
        switch (value) {
            case 'Contact':
                history.push("/contact")
                break;

            case 'Veelgestelde vragen':
                history.push("/faq")
                break;

            case 'Rijzuinig':
                history.push("/home")
                break;

            case 'Zoekresultaat':
                history.push("/list")
                // TODO create query
                /* Router.push({
                     pathname: '/list',
                     query: {
                         brand: getSessionStorageProperty('brand'),
                         year_of_production_min: getSessionStorageProperty('year_of_production_min'),
                         year_of_production_max: getSessionStorageProperty('year_of_production_max'),
                         price_excluding_vat_min: getSessionStorageProperty('price_excluding_vat_min'),
                         price_excluding_vat_max: getSessionStorageProperty('price_excluding_vat_max'),
                         offset: getSessionStorageProperty('offset'),
                         orderBy: getSessionStorageProperty('orderBy'),
                         sortBy: getSessionStorageProperty('sortBy'),
                         titleDropdown: getSessionStorageProperty('titleDropdown'),
                     },
                 })*/

                break;
            default :
                history.push("/list")
            // TODO brand
            /*Router.push({
                pathname: '/list',
                query: {
                    brand: value,
                },
            })*/
        }
    };

    return (
        <div className="container-fluid bg-success p-0">
            <Navbar className="wrapper p-0">
                <ul className="navbar-nav">
                    {
                        props.titles.map((title: string, index: number) => {
                            return <li
                                key={index}
                                className="nav-item arrow-title-between pr-3 text-white"
                                onClick={() => handleClick(title)}
                            >
                                <Nav.Link className="text-white " href="#">
                                    {title}
                                </Nav.Link>
                            </li>
                        })}
                </ul>
            </Navbar>
        </div>
    );
};
