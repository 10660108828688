import React from 'react';
import Carousel from "react-bootstrap/Carousel";

export const CarouselModalCar = (props: any) => {
    const { media, index, setIndex, } = props;

    const handleSelect = (selectedIndex: any) => {
        setIndex(selectedIndex);
    };

    const carouselItems = media.map((item: any, index: number) => {
        return (
            <Carousel.Item key={index}>
                <img
                    className="d-block w-100"
                    src={item.url}
                    alt="car"
                />
            </Carousel.Item>
        )
    });

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            {media.length ? carouselItems : null }
        </Carousel>
    );
};
