import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { DropdownFormInput } from "../dropdowns/DropdownFormInput";
import { itemsDays } from "../../../../Constants";
import {QueryDto} from "../../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../../apis";

export const DaysContainer  = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;
    const {getDropDownItems, countInputs, clearForm, setClearForm, checkVisibleForm} = props;

    const [daysPlaceholder, _setDaysPlaceholder] = useState(queryDto.days /*getSessionStorageProperty('daysPlaceholder')*/ || 'Geen voorkeur');
    const [filteredDaysItems, setFilteredDaysItems] = useState(itemsDays);
    const [inputDaysValue, setInputDaysValue] = useState('');

    const setDaysPlaceholder = (value: any) => {
        queryDto.days=value;
        saveQuery('days', value);
        _setDaysPlaceholder(value);
    }

    if ((clearForm || queryDto.days===undefined) && daysPlaceholder !== 'Geen voorkeur') {
        _setDaysPlaceholder('Geen voorkeur');
    }

    return (
        <Form.Group
            controlId="dropdown-wrapper-days"
            className="col-12 col-md-6 col-lg-3 p-1"
        >
            <Form.Label className="font-weight-bold">
                Dagen op Rijzuinig.nl
                    </Form.Label>
            <DropdownFormInput
                filteredItems={filteredDaysItems}
                getDropDownItems={getDropDownItems}
                placeholder={daysPlaceholder}
                inputValue={inputDaysValue}
                value={'days'}
                countInputs={countInputs}
                setPlaceholder={setDaysPlaceholder}
                setInputValue={setInputDaysValue}
                setFilteredItems={setFilteredDaysItems}
                dropDownItems={itemsDays}
                setClearForm={setClearForm}
                checkVisibleForm={checkVisibleForm}
            />
        </Form.Group>
    )
}