import React, {useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {DropdownMinMax} from './dropdowns/DropdownMinMax';
import {itemsKm} from "../../../Constants";
import {QueryDto} from "../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../apis";

export const KmContainer= (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;
    const {addDropdownMax, addDropdownMin, clearForm, setClearForm, checkVisibleForm,} = props;

    const [kmMin, _setKmMin] = useState(queryDto.km_travelled_min /*getSessionStorageProperty('kmMin')*/ || 'Min.');
    const [kmMax, _setKmMax] = useState(queryDto.km_travelled_max /*getSessionStorageProperty('kmMax')*/ || 'Max.');

    const setKmMin = (value: any) => {
        queryDto.km_travelled_min=value;
        saveQuery('km_travelled_min', value);
        _setKmMin(value);
    }
    const setKmMax = (value: any) => {
        queryDto.km_travelled_max=value;
        saveQuery('km_travelled_max', value);
        _setKmMax(value);
    }
    if ((clearForm || queryDto.km_travelled_min===undefined) && kmMin !== 'Min.') {
        _setKmMin('Min.');
    }

    if ((clearForm || queryDto.km_travelled_max===undefined) && kmMax !== 'Max.') {
        _setKmMax('Max.');
    }

    return (
        <Form.Group className="col-12 col-md-6 col-lg-3 p-1" controlId="dropdownKm">
            <Row className="m-0">
                <Form.Label className="col-4 text-center label-select p-0 m-0 pt-2">
                    Km.stand
                </Form.Label>
                <DropdownMinMax
                    arrayForItems={itemsKm}
                    addDropdown={addDropdownMin}
                    setTitle={setKmMin}
                    title={kmMin}
                    titleCompare={kmMax}
                    setTitleCompare={setKmMax}
                    classProperties={"dropdown-min-max col-4 p-0 m-0"}
                    countAdditionalFields={() => false}
                    countCheckboxDropdownMinMax={() => false}
                    id={"kmMin"}
                    idCompare={"kmMax"}
                    initialValue={'min'}
                    isDivide={true}
                    setClearForm={setClearForm}
                    checkVisibleForm={checkVisibleForm}
                />
                <DropdownMinMax
                    arrayForItems={itemsKm}
                    addDropdown={addDropdownMax}
                    setTitle={setKmMax}
                    title={kmMax}
                    titleCompare={kmMin}
                    setTitleCompare={setKmMin}
                    classProperties={"dropdown-min-max col-4 p-0 m-0"}
                    countAdditionalFields={() => false}
                    countCheckboxDropdownMinMax={() => false}
                    id={"kmMax"}
                    idCompare={"kmMin"}
                    initialValue={'max'}
                    isDivide={true}
                    setClearForm={setClearForm}
                    checkVisibleForm={checkVisibleForm}
                />
            </Row>
        </Form.Group>
    )
}