// import { useHistory } from "react-router-dom";
import * as H from "history";
import {cleanupQuery, jsonToQueryString, QueryDto} from "./RestQueries";


// const history = useHistory();

export const navigate = (path: string, history: H.History<any>) => {
    history.push(path);
}

export const withUniqueAdded = <T>(arr: T[], value: T): T[] => {
    arr = (arr || []).filter(lab => lab !== value);
    arr.push(value);
    return arr;
}
export const withUniqueRemoved = <T>(arr: T[], value: T, setToUndefinedOnEmpty = true): T[] | undefined => {
    arr = (arr || []).filter(lab => lab !== value);
    if (arr.length === 0) {
        return undefined;
    }
    return arr;
}

export const pushNewSearch = (history: any, query: QueryDto) => {
    history.push({
        pathname: '/list',
        search: jsonToQueryString(cleanupQuery({
            ...query,
            orderBy: undefined,
            sortBy: undefined,
            next: undefined
        } as any), true)
    })
}

export const deleteUndefinedFields = (query: any) => {
    if (query !== undefined)
        Object.keys(query).forEach(key => query[key] === undefined ? delete query[key] : {});
}
/*
function debounce<Params extends any[]>(
    func: (...args: Params) => any,
    timeout: number,
): (...args: Params) => void {
    let timer: NodeJS.Timeout
    return (...args: Params) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func(...args)
        }, timeout)
    }
}
*/


