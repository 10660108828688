import React, {useState} from 'react';
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";

export const CarouselCar = (props:any) => {

    const {/*setMainCarFoto, */media,} = props;

    const [index, setIndex] = useState(0);
    // const [direction, setDirection] = useState(null);

    const picturesShow = 3;
    const createSlides = () => {
        let slides = [];
        for (let i = 0; i < media.length; i = i + picturesShow) {
            slides.push(media.slice(i, i + picturesShow));
        }
        return slides;
    }

    const slides = createSlides();

    const handleSelect = (selectedIndex: any, e: any) => {
        setIndex(selectedIndex);

        // TODO no direction field
        //setDirection(e.direction);
    };

    const handleOpenModalPictures = (event: any, index: any) => {
        props.setIndex(index);
        props.setLgShow(true);
    }

    const pictures = slides.map((picture, slideIndex) => {
        return (
            <Carousel.Item
                key={slideIndex}
                className="p-0"
            >
                <Row className="d-flex justify-content-center">
                    {picture.map((elem: any, pictureIndex: any) => {
                        return <img key={pictureIndex} src={elem.url} data-index={picturesShow * slideIndex + pictureIndex}
                                    onClick={(event) => handleOpenModalPictures(event, picturesShow * slideIndex + pictureIndex)}
                                    className="col-4 p-0"
                                    style={{ "maxHeight": "150px"}}
                                    alt="car"/>
                    })}
                </Row>
            </Carousel.Item>
        );
      });

    return (
        <Carousel
            className="p-0 carousel-detail-pagination d-none d-md-inline-block col-md-12"
            activeIndex={index}
            indicators={false}
            keyboard={false}
            controls={true}
            /*direction={direction}*/
            onSelect={handleSelect}
            interval={40000}
        >
            {pictures}
        </Carousel>
    );
};
