import React, {useEffect, useState} from "react";
import {SeoText} from "./SeoText";
// import { brandModelItems } from "../Constants";
import {fetchCeoForBrands, fetchModelsForBrands} from "../../util/RestQueries";

export const SeoTextContainer = (props: any) => {
    const {
        currentBrand,
        setLoadData,
        dataQuery,
    } = props;

    const [seoTexts, setSeoTexts] = useState(null);
    const [modelItems, setModelItems] = useState(null)

    useEffect(() => {
        const fetchData = async () => {

                const promiseSeoText = fetchCeoForBrands(currentBrand)
                const promiseModelItems = fetchModelsForBrands(currentBrand)
                const [resultSeoText, resultModelItems] = await Promise.all([promiseSeoText, promiseModelItems]);


                setSeoTexts(resultSeoText[0] ? resultSeoText[0].ceo_text : null)
                setModelItems(resultModelItems[0] ? resultModelItems.map((titleModel: any) => titleModel.name) : null);

        };

        fetchData();
    }, [currentBrand]);

    return (
        <SeoText
            currentBrand={currentBrand}
            setLoadData={setLoadData}
            seoTexts={seoTexts}
            dataQuery={dataQuery}
            modelItems={modelItems}
        />
    );
};
