import React, {useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {DropdownMinMax} from './dropdowns/DropdownMinMax';
import {itemsYear} from "../../../Constants";
import {QueryDto} from "../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../apis";

export const YearContainer = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const {addDropdownMax, addDropdownMin, clearForm, setClearForm, checkVisibleForm,} = props;
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;

    const [yearMin, _setYearMin] = useState(queryDto.year_of_production_min /*getSessionStorageProperty('yearMin')*/ || 'Min.');
    const [yearMax, _setYearMax] = useState(queryDto.year_of_production_max /*getSessionStorageProperty('yearMax')*/ || 'Max.');

    const setYearMin = (value: any) => {
        queryDto.year_of_production_min = value;
        saveQuery('year_of_production_min', value);
        _setYearMin(value);
    }
    const setYearMax = (value: any) => {
        queryDto.year_of_production_max = value;
        saveQuery('year_of_production_max', value);
        _setYearMax(value);
    }
    if ((clearForm || queryDto.year_of_production_min===undefined) && yearMin !== 'Min.') {
        _setYearMin('Min.');
    }

    if ((clearForm || queryDto.year_of_production_max===undefined) && yearMax !== 'Max.') {
        _setYearMax('Max.');
    }

    return (
        <Form.Group controlId="dropdownYear" className="col-12 col-md-6 col-lg-3 p-1">
            <Row className="m-0 p-0 label-select">
                <Form.Label className="col-4 text-center p-0 m-0 pt-2 dropdown-min-max">Bouwjaar</Form.Label>
                <DropdownMinMax
                    arrayForItems={itemsYear}
                    addDropdown={addDropdownMin}
                    setTitle={setYearMin}
                    title={yearMin}
                    titleCompare={yearMax}
                    setTitleCompare={setYearMax}
                    classProperties={"dropdown-min-max col-4 p-0 m-0"}
                    countAdditionalFields={() => false}
                    countCheckboxDropdownMinMax={() => false}
                    id={"yearMin"}
                    idCompare={"yearMax"}
                    initialValue={'min'}
                    setClearForm={setClearForm}
                    checkVisibleForm={checkVisibleForm}
                />
                <DropdownMinMax
                    arrayForItems={itemsYear}
                    addDropdown={addDropdownMax}
                    setTitle={setYearMax}
                    title={yearMax}
                    titleCompare={yearMin}
                    setTitleCompare={setYearMin}
                    classProperties={"dropdown-min-max col-4 p-0 m-0"}
                    countAdditionalFields={() => false}
                    countCheckboxDropdownMinMax={() => false}
                    id={"yearMax"}
                    idCompare={"yearMin"}
                    initialValue={'max'}
                    setClearForm={setClearForm}
                    checkVisibleForm={checkVisibleForm}
                />
            </Row>
        </Form.Group>
    )
}