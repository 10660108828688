import React, {useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {DropdownMinMax} from './dropdowns/DropdownMinMax';
import {itemsPrice} from "../../../Constants";
import {QueryDto} from "../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../apis";

export const PriceContainer = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;
    const {addDropdownMax, addDropdownMin, clearForm, setClearForm, checkVisibleForm,} = props;

    const [priceMin, _setPriceMin] = useState(queryDto.price_excluding_vat_min /*getSessionStorageProperty('priceMin')*/ || 'Min.');
    const [priceMax, _setPriceMax] = useState(queryDto.price_excluding_vat_max /*getSessionStorageProperty('priceMax')*/ || 'Max.');

    const setPriceMin = (value: any) => {
        queryDto.price_excluding_vat_min = value;
        saveQuery('price_excluding_vat_min', value);
        _setPriceMin(value);
    }
    const setPriceMax = (value: any) => {
        queryDto.price_excluding_vat_max = value;
        saveQuery('price_excluding_vat_max', value);
        _setPriceMax(value);
    }
    if ((clearForm || queryDto.price_excluding_vat_min === undefined) && priceMin !== 'Min.') {
        _setPriceMin('Min.');
    }

    if ((clearForm || queryDto.price_excluding_vat_max === undefined) && priceMax !== 'Max.') {
        _setPriceMax('Max.');
    }

    return (
        <Form.Group controlId="dropdownPrice" className="col-12 col-md-6 col-lg-3 p-1">
            <Row className="m-0 p-0 label-select">
                <Form.Label className="col-4 text-center p-0 m-0 pt-2 dropdown-min-max">Prijs</Form.Label>
                <DropdownMinMax
                    arrayForItems={itemsPrice}
                    addDropdown={addDropdownMin}
                    setTitle={setPriceMin}
                    title={priceMin}
                    titleCompare={priceMax}
                    setTitleCompare={setPriceMax}
                    classProperties={"dropdown-min-max col-4 p-0 m-0"}
                    id={"priceMin"}
                    idCompare={"priceMax"}
                    initialValue={'min'}
                    countCheckboxDropdownMinMax={() => false}
                    isDivide={true}
                    setClearForm={setClearForm}
                    checkVisibleForm={checkVisibleForm}
                />
                <DropdownMinMax
                    arrayForItems={itemsPrice}
                    addDropdown={addDropdownMax}
                    setTitle={setPriceMax}
                    title={priceMax}
                    titleCompare={priceMin}
                    setTitleCompare={setPriceMin}
                    classProperties={"dropdown-min-max col-4 p-0 m-0"}
                    id={"priceMax"}
                    idCompare={"priceMin"}
                    initialValue={'max'}
                    countCheckboxDropdownMinMax={() => false}
                    isDivide={true}
                    setClearForm={setClearForm}
                    checkVisibleForm={checkVisibleForm}
                />
            </Row>
        </Form.Group>
    )
}