import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

export const DropdownFormInput = (props:any) => {
    const {
        countInputs,
        filteredItems,
        getDropDownItems,
        placeholder,
        inputValue,
        value,
        setPlaceholder,
        setInputValue,
        setFilteredItems,
        dropDownItems,
        setClearForm,
        checkVisibleForm,
    } = props;

    const handleChange = (valueInput: any) => {
        setInputValue(valueInput);
        setPlaceholder(valueInput);
        setClearForm(false);
        checkVisibleForm(0, valueInput);
    };
    const filterTitles = (valueInput: any) => {
    const toLowerCaseValue = valueInput.toLowerCase();

        setInputValue(valueInput);
        setFilteredItems(dropDownItems.filter((str: string) => str.toLowerCase().indexOf(toLowerCaseValue) >= 0));
    };
    return (
        <Dropdown
            className="p-0 bg-white border-form-input"
            onClick={() =>  {
                        setInputValue('');
                        filterTitles('');
                        }} 
            onSelect={(eventKey) => {
                handleChange(eventKey);
                countInputs("dropdown-" + value, eventKey);
            }}
        >
            <Dropdown.Toggle
                id={"dropdown-" + value}
                as="div"
                className="w-100 bg-white"
            >
                <input
                    type="text"
                    placeholder={placeholder}
                    className="bg-white w-100 text-secondary shadow-none pl-3"
                    value={inputValue}
                    onChange={(event) => {filterTitles(event.currentTarget.value);}}
                />
            </Dropdown.Toggle>
            <Dropdown.Menu className="col-md-3 m-0 p-0">
                {getDropDownItems(filteredItems, placeholder)}
            </Dropdown.Menu>
        </Dropdown>
    )
};
