import React, {useState} from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import DropdownButton from "react-bootstrap/DropdownButton";
// import Dropdown from "react-bootstrap/Dropdown";
// import Modal from "react-bootstrap/Modal";
// import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ReCAPTCHA from 'react-google-recaptcha';
import {ModalThanks} from "../../layout/ModalThanks";
import {ContactDto, sendContactEmail} from "../../util/RestQueries";

export const FormContact = () => {
    const [showThanks, setShowThanks] = useState(false);
    const [validated, setValidated] = useState(false);

    // const [dropdownContactTitle, setDropdownContactTitle] = useState('Algemeen');
    const [recaptchaResponse, setRecaptchaResponse] = useState(null);

    let sendData = function (event: any) {
        const contact: any = {}
        const elements = [...event.target.elements] // map from html collection to array
        elements.forEach(elem => {
            if (elem.id !== "")
                contact[elem.id] = elem.value;
        })
        sendContactEmail(contact as ContactDto)
            .then(e => setShowThanks(true));
    };
    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();


        const form = event.currentTarget;
        if (form.checkValidity() && recaptchaResponse) {
            sendData(event);
        }
        setValidated(true);
        // setRecaptchaResponse(null)
    };

    const closeModalContact = () => {
        setShowThanks(false);
        window.location.reload();
    };

    const handleCaptchaResponseChange = (response: any) => {
        setRecaptchaResponse("recaptcha" as any)
    }

    return (
        <Form className="form p-3" noValidate validated={validated} onSubmit={handleSubmit}>
            {/* <Form.Label>Soort</Form.Label>
            <Dropdown>
                <Dropdown.Toggle
                    required
                    id="dropdown-form-car"
                    title={dropdownContactTitle}
                    className="dropdown-min-max mb-2 p-2 col-12 col-md-8 col-lg-8 d-flex justify-content-between text-secondary bg-white shadow-none border-none border-white"
                >
                    {dropdownContactTitle}
                </Dropdown.Toggle>
                <Dropdown.Menu className="col-12 col-md-8 col-lg-8">
                    <Dropdown.Item onClick={() => setDropdownContactTitle('1...')}>
                        {'1...'}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setDropdownContactTitle('1...')}>
                        {'2...'}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown> */}
            <Form.Group
                controlId="sender_name"
                className="p-0 col-12 col-md-8 col-lg-8"
            >
                <Form.Label>Naam</Form.Label>
                <Form.Control
                    required
                    type="text"
                    className="rounded-0 border-white"
                />
                <Form.Control.Feedback>Ziet er goed uit!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group
                controlId="sender_email"
                className="p-0 col-12 col-md-8 col-lg-8"
            >
                <Form.Label>E-mailadres</Form.Label>
                <Form.Control
                    type="email"
                    required
                    className="rounded-0 border-white"
                />
                <Form.Control.Feedback>Ziet er goed uit!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="p-0 col-12 col-md-8 col-lg-8" controlId="topic">
                <Form.Label>Onderwerp</Form.Label>
                <Form.Control
                    required
                    className="rounded-0 border-white"
                    type="text"
                />
                <Form.Control.Feedback>Ziet er goed uit!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group
                className="p-0 col-12 col-md-8 col-lg-8"
                controlId="question_or_remark"
            >
                <Form.Label>Vraag / opmerking</Form.Label>
                <Form.Control
                    required
                    as="textarea"
                    /*   rows="1"*/
                    className="rounded-0 border-white"
                />
                <Form.Control.Feedback>Ziet er goed uit!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <ReCAPTCHA
                    sitekey="6LflbNUZAAAAACX7ioe4VcZPvF55RY3pTLmH7s54"
                    onChange={handleCaptchaResponseChange}
                />
            </Form.Group>
            <Button
                className="pl-5 pr-5 bg-success required rounded-0 border-0"
                type="submit"
            >
                Verstuur bericht
            </Button>
            <ModalThanks
                show={showThanks}
                onHide={() => closeModalContact()}
            />
        </Form>
    );
};
//   disabled={!validated || recaptchaResponse===null}