import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {DropdownRadius} from "../dropdowns/DropdownRadius";
import {itemsRadius} from "../../../../Constants";
import {QueryDto} from "../../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../../apis";

export const RadiusContainer = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;


    const calculateRadius = (q: any) => {
        if (!q.radius)
            return 'Straal...';
        if (!q.radius.endsWith(" km")) {
            return q.radius+" km";
        }
        return q.radius;
    }
    const [radiusPlaceholder, _setRadiusPlaceholder] = useState(calculateRadius(queryDto));
    const [filteredRadiusItems, setFilteredRadiusItems] = useState(itemsRadius);
    const [inputRadiusValue, setInputRadiusValue] = useState('');
    const [postcode, _setPostcode] = useState(queryDto.postcode || '');

    const setRadiusPlaceholder = (value: any) => {
        let valueForQuery=value;
        if (value !== undefined && (''+value).endsWith(" km")) {
            valueForQuery=''+value.substring(0, value.length-3);
        }
        queryDto.radius=valueForQuery;

        saveQuery('radius', value);
        _setRadiusPlaceholder(value);
    }
    const setPostcode = (value: any) => {
        queryDto.postcode=value;
        saveQuery('postcode', value);
        _setPostcode(value);
    }

    if ((props.clearForm || queryDto.radius===undefined) && radiusPlaceholder !== 'Straal...') {
        _setRadiusPlaceholder('Straal...');
    }

    if ((props.clearForm || queryDto.postcode === undefined) && postcode !== '') {
        _setPostcode('');
    }

    return (
        <Form.Group
            className="col-12 col-md-6 col-lg-3 p-1"
            controlId="postcodeWrapper"
        >
            <Form.Label className="font-weight-bold">
                Postcode & Straal
            </Form.Label>
            <Row className="m-0">
                <Form.Control
                    type="text"
                    className="rounded-0 col-5 col-md-5 border-form-input text-secondary"
                    placeholder="Postcode"
                    /*controlid="postcode" TODO nie ma*/
                    onChange={(event: any) => setPostcode(event.target.value)}
                    onBlur={(event: any) => props.countInputs('postcode', event.target.value)}
                    value={postcode || ''}
                />
                <DropdownRadius
                    radiusPlaceholder={radiusPlaceholder}
                    inputRadiusValue={inputRadiusValue}
                    getDropDownItems={props.getDropDownItems}
                    filteredRadiusItems={filteredRadiusItems}
                    setRadiusPlaceholder={setRadiusPlaceholder}
                    setFilteredRadiusItems={setFilteredRadiusItems}
                    setInputRadiusValue={setInputRadiusValue}
                    radiusItems={itemsRadius}
                    setClearForm={props.setClearForm}
                />
            </Row>
        </Form.Group>
    )
}