export const optionsFirstColumn = [
    { id: 'ABS', label: 'ABS' },
    { id: 'airbags', label: 'Airbags' },
    { id: 'airconditioning', label: 'Airconditioning' },
    { id: 'alarm', label: 'Alarm' },
    { id: 'elektrisch verstelbare bestuurdersstoel', label: 'Bestuurdersstoel in hoogte verstelbaar' },
    { id: 'boordcomputer', label: 'Boordcomputer' },
    { id: 'bumpers', label: 'Bumpers in carrosseriekleur' },
];
export const optionsSecondColumn = [
    { id: 'centrale vergrendeling', label: 'Centrale vergrendeling' },
    { id: 'climate control', label: 'Climate control' },
    { id: 'cruise control', label: 'Cruise control' },
    { id: 'elek. ramen voor + achter', label: 'Elektrisch bedienbare ramen' },
    { id: 'rear_headrests', label: 'Hoofdsteunen achter' },
    { id: 'lederen bekleding', label: 'Lederen bekleding' },
    { id: 'leather_handlebar', label: 'Lederen stuur' },
];
export const optionsThirdColumn = [
    { id: 'lichtmetalen velgen', label: 'Lichtmetalen velgen' },
    { id: 'armsteun voor', label: 'Middenarmsteun voor' },
    { id: 'mistlampen', label: 'Mistlampen' },
    { id: 'navigation_system', label: 'Navigatiesysteem' },
    { id: 'panoramic_roof', label: 'Panoramadak' },
    { id: 'parkeersensor', label: 'Parkeersensor' },
    { id: 'radio CD speler', label: 'Radio/CD' },
];
export const optionsFourthColumn = [
    { id: 'regensensor', label: 'Regensensor' },
    { id: 'slide_open_roof', label: 'Schuif/open dak' },
    { id: 'startblokkering', label: 'Startonderbreker' },
    { id: 'voorstoelen verwarmd', label: 'Stoelverwarming' },
    { id: 'stuurbekrachtiging', label: 'Stuurbekrachting' },
    { id: 'towbar', label: 'Trekhaak' },
    { id: 'xenon_integration', label: 'Xenon verlichting' },
];
export const colorsFirstColumn = [
    { id: 'Beige' },
    { id: 'Blauw' },
    { id: 'Bruin' },
    { id: 'Geel' },
    { id: 'Goud' },
];
export const colorsSecondColumn = [
    { id: 'Grijs' },
    { id: 'Groen' },
    { id: 'Oranje' },
    { id: 'Paars' },
    { id: 'Rood' },
];
export const colorsThirdColumn = [
    { id: 'Wit', className: 'col-6 col-md-12' },
    { id: 'Zilver', className: 'col-5 col-md-12 ml-1 ml-md-0' },
    { id: 'Zwart', className: 'col-6 col-md-12' },
];
export const doorsColumn = [
    { id: '2', label: '2-deurs', className: 'order-md-1 order-1 col-6 col-md-12 ml-1' },
    { id: '3', label: '3-deurs', className: 'order-md-2 order-3 col-6 col-md-12 ml-1' },
    { id: '4', label: '4-deurs', className: 'order-md-3 order-5 col-6 col-md-12 ml-1' },
    { id: '5', label: '5-deurs', className: 'order-md-4 order-2 col-5 col-md-12 ml-0 ml-md-1' },
    { id: '6', label: '6-deurs', className: 'order-md-5 order-4 col-5 col-md-12 ml-0 ml-md-1' },
];
export const guarantee = [
    { id: 'bovag_warranty', label: 'BOVAG-garantie' },
    { id: 'brand_guarantee', label: 'Merkgarantie' },
];
export const nap = [
    { id: 'national_car_pass', label: 'Nationale Auto Pas' },
];
export const btw = [
    { id: 'vat_car', label: 'Btw-auto' },
];
export const options = [
    'ABS',
    'airbags',
    'airconditioning',
    'alarm',
    'elektrisch verstelbare bestuurdersstoel',
    'boordcomputer',
    'bumpers',
    'centrale vergrendeling',
    'climate control',
    'cruise control',
    'elek. ramen voor + achter',
    'rear_headrests',
    'lederen bekleding',
    'leather_handlebar',
    'lichtmetalen velgen',
    'armsteun voor',
    'mistlampen',
    'navigation_system',
    'panoramic_roof',
    'parkeersensor',
    'radio CD speler',
    'regensensor',
    'slide_open_roof',
    'startblokkering',
    'voorstoelen verwarmd',
    'stuurbekrachtiging',
    'towbar',
    'xenon_integration',
];
export const colors = [
    'Beige',
    'Blauw',
    'Bruin',
    'Geel',
    'Goud',
    'Grijs',
    'Groen',
    'Oranje',
    'Paars',
    'Rood',
    'Wit',
    'Zilver',
    'Zwart',
];
export const doors = ['2-deurs', '3-deurs', '4-deurs', '5-deurs', '6-deurs'];
export const energyLabel = ['A', 'B', 'C', 'D', 'E'];
export const itemsGuarantee = ['bovag_warranty', 'brand_guarantee'];

export const itemNap = ['national_car_pass'];
export const itemBtw = ['vat_car'];

export const itemsLocalStorage = [
    'yearMin',
    'yearMax',
    'priceMin',
    'priceMax',
    'kmMin',
    'kmMax',
    'fuelPlaceholder',
    'transmissionsPlaceholder',
    'bodyWorkPlaceholder',
    'additionPlaceholder',
    'daysPlaceholder',
    'privateCarDealerPlaceholder',
    'radiusPlaceholder',
    'cylinderCapacityMin',
    'cylinderCapacityMax',
    'amountCylindersMin',
    'amountCylindersMax',
    'powerMin',
    'powerMax',
    'seatsMin',
    'seatsMax',
    'weightMin',
    'weightMax',
    'quantityAdditionalFields',
    'additionalFieldsCheckbox',
    'quantityAdditionalFieldsDropdown',
    'brandItems',
    'brandTitle',
    'modelItems',
    'modelTitle',
    'description'
];
export const popularModels = [
    { brand: "Volkswagen", model: "Golf" },
    { brand: "BMW", model: "3-serie" },
    { brand: "Volkswagen", model: "Polo" },
    { brand: "Audi", model: "A4" },
    { brand: "BMW", model: "5-serie" },
    { brand: "Ford", model: "Focus" },
    { brand: "Audi", model: "A3" },
    { brand: "Opel", model: "Astra" },
];

export const itemsType = [
    'Carrosserie...',
    'Cabriolet',
    'Coupé',
    'Hatchback',
    'MPV',
    'Sedan',
    'Stationwagon',
    'Terreinwagen',
    'SUV'
];
export const itemsFuel = ['Brandstof...', 'Aardgas (CNG)', 'Benzine', 'Diesel', 'Elektrisch', 'Hybride', 'LPG-G3', 'LPG'];
export const itemsEnergyCounting = ['4%','8%','21%','22%'];

export const itemsDays = ['Geen voorkeur', '24 uur', '2 dagen', '3 dagen', '5 dagen', '7 dagen', '14 dagen'];
export const brandModelItems = 'https://rijzuinig.nl:8080';
export const itemsYear = [
    2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004,
    2003, 2002, 2001, 2000, 1999, 1998, 1997, 1996, 1995, 1994, 1993, 1992, 1991, 1990, 1989, 1988, 1987,
    1986, 1985, 1984, 1983, 1982, 1981, 1980, 1979, 1978, 1977, 1976, 1975, 1974, 1973, 1972, 1971, 1970,
    1965, 1960, 1950, 1940, 1930, 1920, 1910, 1900
];
export const itemsPrice = [
    250, 500, 600, 750, 1000, 1250, 1500, 2000, 2500, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 12500,
    15000, 17000, 20000, 22500, 25000, 27500, 30000, 35000, 40000, 45000, 50000, 60000, 75000, 100000,
    150000, 200000
]
export const itemsKm = [
    1, 5000, 10000, 20000, 40000, 60000, 80000, 100000, 120000, 140000, 160000, 180000, 200000, 250000
];
export const itemsCylinderCapacity = [
    250, 500, 1000, 1200, 1400, 1600, 1800, 2000, 2200, 2500, 2700, 3000, 3500, 4000, 5000
];
export const itemsAmountCilinders = [2, 3, 4, 5, 6, 8, 10, 12];
export const itemsSeats = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const itemsWeight = [500, 750, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750, 3000, 3250, 3500];
export const itemsPower = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 120, 140, 160, 180, 200, 250, 300, 350, 400];
export const itemsRadius = ['5 km', '10 km', '20 km', '30 km', '40 km', '50 km', '75 km', '100 km'];
export const itemsTransmissions = ['Transmissie...', 'Automaat', 'Handgeschakeld'];
