
/**
 * Initialize tool by dispatching DOMContentLoaded.
 *
 * @returns {void}
 */
export const FinancialLeaseInitTrigger = (): void => {
    window.document.dispatchEvent(new Event("DOMContentLoaded", {
        bubbles: true,
        cancelable: true
    }));
}

/**
 * Custom wrapper for lease.auto finance tool trigger.
 *
 * This tool uses unconventional triggers that's why it's wrapped.
 *
 * @param {number} price
 * @param {JSX.Element|string} innerHtml
 * @returns {HTMLButtonElement}
 */
export const FinancialLeaseButton = (price:number, innerHtml: JSX.Element|string): HTMLButtonElement => (
    // @ts-ignore
    <lease-button role="button" price={price} dealer="3949">{innerHtml}</lease-button> as unknown as HTMLButtonElement
);