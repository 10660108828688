import React, {useState} from 'react';
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Dropdown from 'react-bootstrap/Dropdown';

import {YearContainer} from './YearContainer';
import {setValuesSessionStorage} from "../../../util/Session";
import {BrandModelContainer} from "./BrandModelContainer";
import {PriceContainer} from "./PriceContainer";
import {FuelContainer} from "./FuelContainer";
import {KmContainer} from "./KmContainer";
import {TransmissionsContainer} from "./TransmissionsContainer";
import {BodyWorkContainer} from "./BodyWorkContainer";
import {EnergyRatingContainer} from "./EnergyRatingContainer";
import {FormListAddition} from "./FormListAddition";
import {cleanupQuery, getAdditionalCount, getBaseCount, QueryDto} from "../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../apis";
import {safeScrollTop} from "../../../util/domainUtils";
import {EnergyCountingContainer} from "./dropdowns/EnergyCountingContainer";

export const FormList = (props: {
    submitQuery: (query: QueryDto) => void,
    formQuery: any,
    setFormQuery?: any
}) => {
    const queryDto = props.formQuery;
    const setFormQuery = props.setFormQuery;


    const additionalCount = getAdditionalCount(queryDto);
    const shouldWis = (getBaseCount(queryDto) + additionalCount) > 0;
    const [visibleClearButton, setVisibleClearButton] = useState(shouldWis);
    if (visibleClearButton !== shouldWis) {
        setVisibleClearButton(shouldWis);
    }
    const [totalAdditionalFields, setTotalAdditionalFields] = useState(additionalCount);
    if (totalAdditionalFields !== additionalCount) {
        setTotalAdditionalFields(additionalCount);
    }


    //console.log("FormList rerendering, query is ", queryDto);
    const saveQuery: SaveQueryDefault = (fieldName: string, value) => {
        console.log("##changed " + fieldName + " " + value);
        cleanupQuery(queryDto);
        // setValuesSessionStorage("queryDto", queryDto);

        const additional = getAdditionalCount(queryDto);
        const base = getBaseCount(queryDto);
        if (setFormQuery !== undefined)
            setFormQuery({...queryDto});
        setTotalAdditionalFields(additional);
        setClearForm((base + additional) === 0);
        if (base + additional > 0) {
            setVisibleClearButton(true);
        } else {
            setVisibleClearButton(false);
        }

        console.log("##query is saved ", queryDto);

    };

    const [additionalDropdownMinMax, setAdditionalDropdownMinMax] = useState([]);
    const [clearForm, setClearForm] = useState(false);
    const [inputTextValue, setInputTextValue] = useState('');
    // Hook State: visible expand search form
    const [visibleAdditionForm, addAdditionForm] = useState(true);


    const addDropdownMax = (arr: any) => ['Max.', ...arr];
    const addDropdownMin = (arr: any) => ['Min.', ...arr];

    const getDropDownItems = (arr: any[], currentValue: any) => {
        return arr.map((title, index) => <Dropdown.Item key={index} eventKey={title}
                                                        active={currentValue === title}>{title}</Dropdown.Item>)
    };
    const checkVisibleForm = (number = 0, string = '') => {
        if (number > 0 || string !== '') {
            setVisibleClearButton(true);
        } else {
            setVisibleClearButton(false);
        }
    }
    const handleSubmitContainer = (obj: any) => {
        cleanupQuery(queryDto);
        setValuesSessionStorage("queryDto", queryDto);

        props.submitQuery({...queryDto});

    };

    const handleSubmit = (event: any) => {
        addAdditionForm(true);
        event.preventDefault();
        event.stopPropagation();
        handleSubmitContainer(null as any);
    };

    const handleClick = () => (addAdditionForm(!visibleAdditionForm));

    const countInputs = (id: any, value: any) => {
        // const isValue = value && (value !== 'Geen voorkeur');
        // const filteredFields = additionalFields.filter((item: any) => item !== id);
        // if (isValue)
        //     filteredFields.push(id);
        //
        // setAdditionalFields(filteredFields);
        // setQuantityAdditionalFields(filteredFields.length)
        // checkVisibleForm(filteredFields.length);
    };


    const countCheckboxDropdownMinMax = (dropdownTitle: any, dropdownItem: any) => {
        const uniqueFields: any[] = [];
        const checkValue = (value: any) => ('Min.' === value) || ('Max.' === value);
        let filteredFields: any = [...additionalDropdownMinMax];
        let currentDropdown = dropdownTitle.slice(0, -3);

        filteredFields = filteredFields.filter((item: any) => item.id !== dropdownTitle);

        if (!checkValue(dropdownItem)) {
            const dropdown = {
                id: dropdownTitle,
                value: dropdownItem,
                generalDropdown: currentDropdown,
            };
            filteredFields.push(dropdown);
        }

        filteredFields.forEach((field: any) => uniqueFields.includes(field.generalDropdown)
            ? null
            : uniqueFields.push(field.generalDropdown));

        setAdditionalDropdownMinMax(filteredFields);
        checkVisibleForm(uniqueFields.length);
    };


    const resetFormList = () => {

        Object.keys(queryDto).forEach(function (key) {
            delete queryDto[key];
        });
        setValuesSessionStorage("queryDto", queryDto);
        setTotalAdditionalFields(getAdditionalCount(queryDto));

        setClearForm(true);
        checkVisibleForm();
        safeScrollTop("MOBILE");

    }


    return (<>
            <Form
                noValidate
                validated={false}
                onSubmit={handleSubmit}
                className="header-form-homepage p-0 pt-2 col-12"

            >
                {/* <Row className="m-0 pl-2 pr-2">
                    <Row className="m-0 pl-2 pr-2">
                        <div>
                            <pre>{jsonToQueryString(queryDto)}</pre>
                        </div>
                    </Row>
                    <Row className="m-0 pl-2 pr-2">
                        <div>
                            <pre>{jsonToQueryString(valueForQuery)}</pre>
                        </div>
                    </Row>
                </Row>*/}

                <Row className="m-0 pl-2 pr-2">
                    <BrandModelContainer
                        queryDto={queryDto}
                        saveQuery={saveQuery}
                        checkVisibleForm={checkVisibleForm} clearForm={clearForm}
                        setClearForm={setClearForm}/>
                    <YearContainer queryDto={queryDto}
                                   saveQuery={saveQuery}
                                   checkVisibleForm={checkVisibleForm} addDropdownMax={addDropdownMax}
                                   addDropdownMin={addDropdownMin} clearForm={clearForm} setClearForm={setClearForm}/>
                    <PriceContainer
                        queryDto={queryDto}
                        saveQuery={saveQuery}
                        checkVisibleForm={checkVisibleForm} addDropdownMax={addDropdownMax}
                        addDropdownMin={addDropdownMin} clearForm={clearForm} setClearForm={setClearForm}/>
                </Row>
                <Row className="m-0 pl-2 pr-2">
                    <FuelContainer
                        queryDto={queryDto}
                        saveQuery={saveQuery}
                        checkVisibleForm={checkVisibleForm} getDropDownItems={getDropDownItems}
                        clearForm={clearForm} setClearForm={setClearForm}/>
                    <KmContainer
                        queryDto={queryDto}
                        saveQuery={saveQuery}
                        checkVisibleForm={checkVisibleForm} addDropdownMax={addDropdownMax}
                        addDropdownMin={addDropdownMin} clearForm={clearForm} setClearForm={setClearForm}/>
                    <TransmissionsContainer
                        queryDto={queryDto}
                        saveQuery={saveQuery}
                        checkVisibleForm={checkVisibleForm} getDropDownItems={getDropDownItems}
                        clearForm={clearForm} setClearForm={setClearForm}/>
                    <BodyWorkContainer
                        queryDto={queryDto}
                        saveQuery={saveQuery}
                        checkVisibleForm={checkVisibleForm} getDropDownItems={getDropDownItems}
                        clearForm={clearForm} setClearForm={setClearForm}/>
                </Row>
                <Row className="d-flex m-0 pl-2 pr-2 pb-2 justify-content-end">
                    <Row  className="col-12 col-md-6 m-0 p-0">
                        <Form.Group className="row col-12 p-1 m-0"
                                    controlId="formGridState"
                        >
                            <EnergyRatingContainer queryDto={queryDto}
                                               saveQuery={saveQuery}/>
                            {/*<div className="button-form-wrapper">
                            <EnergyCountingContainer  queryDto={queryDto}
                                                      saveQuery={saveQuery}
                                                      checkVisibleForm={checkVisibleForm}
                                                      clearForm={clearForm} setClearForm={setClearForm}/>
                            </div>*/}
                        </Form.Group>
                      {/*  <Form.Group controlId="dropdownEnergyCounting"
                                    className="col-4 p-1 m-0 ">
                          </Form.Group>
*/}
                    </Row>
                    <Row className="col-12 col-md-1 m-0 p-0">
                            {/*<div>Visible form {""+visibleClearButton} {""+visibleAdditionForm}</div>*/}
                        <Row className="col-12 col-md-6 m-0 p-0">
                            <div className="button-form-wrapper">
                            <Button
                                className={`${visibleClearButton && visibleAdditionForm ? '' : 'clean-hidden'} clean-btn col-12 ml-auto font-weight-bold rounded-0 `}
                                onClick={() => resetFormList()}
                            >
                                WIS
                            </Button></div>
                        </Row>
                    </Row>
                    <Row className="col-12 col-md-5 m-0 p-0">
                        <label
                            className="order-4 order-sm-4 order-md-3 ml-auto ml-md-auto label-form-homepage-extended h-100 m-auto mr-md-0 mt-md-0 pt-2 col-md-6 text-center text-md-right"
                        >
                            <input type="checkbox" onClick={handleClick}/>
                            <p className="user-select clean-margin text-form-homepage-extended font-weight-bold cursor-pointer pr-2">
                                Uitgebreid zoeken {totalAdditionalFields ? `(${totalAdditionalFields} filters)` : null}
                            </p>
                        </label>
                        <div className="button-form-wrapper order-3 order-sm-3 order-md-4 col-md-6">
                            <Button
                                className="w-100 btn-success button-form-homepage font-weight-bold rounded-0"
                                type="submit"
                            >
                                Zoeken
                            </Button>
                        </div>
                    </Row>
                </Row>
                <div className={visibleAdditionForm ? "form-addition-hidden" : 'form-addition p-2'}>
                    <FormListAddition
                        queryDto={queryDto}
                        saveQuery={saveQuery}
                        getDropDownItems={getDropDownItems}
                        addDropdownMax={addDropdownMax}
                        addDropdownMin={addDropdownMin}
                        countCheckboxDropdownMinMax={countCheckboxDropdownMinMax}
                        countInputs={countInputs}
                        visibleAdditionForm={visibleAdditionForm}
                        resetFormList={resetFormList}
                        clearForm={clearForm}
                        setClearForm={setClearForm}
                        inputTextValue={inputTextValue}
                        setInputTextValue={setInputTextValue}
                        visibleForm={visibleClearButton}
                        checkVisibleForm={checkVisibleForm}
                    />
                </div>
            </Form>
        </>
    )
};
