import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import {Navigation} from '../../layout/Navigation';

import {useHistory} from "react-router-dom";


import {AccordionCar} from './AccordionCar';
//import { CarouselModalCar } from './CarouselModalCar';
import qs from "qs";
import {CarouselCar} from "./CarouselCar";
import {CarouselModalCar} from "./CarouselModalCar";
import {isQueryEmpty} from "../../util/RestQueries";
import {Banner, Banners, callForBanners} from "../home/Banner";
import {formatPrice} from "../../util/domainUtils";
import {FinancialLeaseButton, FinancialLeaseInitTrigger} from "../../util/AutoLease/AutoLease";
import "../../util/AutoLease/AutoLease.css";


export const CarPageInternal = (props: any) => {
    const history = useHistory();
    const {advertisement, specification, hexon_uuid, name, address, phone, email, city, zipcode} = props.advertiser;
    const advertiser_id = props.advertiser.id;
    const {media, pricing, brand, description, feature_image} = advertisement;
    const advertisement_id=advertisement.id;
    const {
        options,
        model,
        license_plate,
        is_margin,
        performance,
        color,
        transmission,
        type,
        fuel,
        number_of_doors,
        towing_weight_kg,
        year_of_production,
        km_travelled,
        number_of_seats,
        energy_label,
        fuel_consumption_rate_l,
        horse_power,
        engine_capacity_cc,
        mot_valid_until,
        is_imported,
        has_maintenance_booklet,
        bovag_warranty,
        brand_warranty,
        nap,
        top_speed,
        date_part_1,
        road_tax,
        acceleration,
        amount_of_cylinders,
        towing_weight_max,
        towing_weight_unrestrained
    } = specification;

    const [banners, setBanners] = useState(new Banners({}, 0));

    useEffect(() => {
        // Initialize lease.auto finance tool.
        FinancialLeaseInitTrigger()
        return callForBanners(setBanners);
    }, []);


    const {price_excluding_vat} = pricing[0];

    let urlMainImgCar = null;
    const [index, setIndex] = useState(0);
    const [lgShow, setLgShow] = useState(false);
    const [imageShow, setImageShow] = useState(false);

    const setMainCarFoto = (url: any) => {
        urlMainImgCar = url;
    };

    const transformMotValidUntil = (date: any) => {
        if (date === null)
            return null;
        let time = new Date(parseInt(date));
        return time.toLocaleDateString();
    }

    const getPreviousPage = () => {

        // TODO not sure if this is right replacement
        const query = qs.parse(history.location.search, {ignoreQueryPrefix: true})
        if (!isQueryEmpty(query)) {
            history.replace({
                pathname: '/list',
                search: history.location.search,
            })
            return;
        }
        /* if (query.brand) {
           return Router.push({
             pathname: '/list',
             query,
           });
         }*/
        history.push("/home")

        /*Router.push({
          pathname: '/home',
        });*/
    };

    const titles = ['Rijzuinig', 'Zoekresultaat', `${brand}`];

    return (
        <>
            <Navigation titles={titles}/>
            <section className="col-12 wrapper row mb-5">
                <main className="col-12 col-md-10 m-0 p-0 car-main">
          <span
              className="nav-link text-secondary cursor-pointer"
              onClick={() => getPreviousPage()}
          >
            &#60; <u>Terug naar overzicht</u>
          </span>
                    <Row>
                        <section className="col-12 col-md-7">
                            <section className="col-12 p-0 mb-3 mb-md-5 cursor-pointer" onClick={() => setLgShow(true)}>
                                <div
                                    className=" col-12 container-fluid img-card-carousel car-details p-0 position-relative">
                                    <img
                                        src={urlMainImgCar || (media.length === 0 ? null : media[0].url)}
                                        className="w-100"
                                        alt="car"
                                    />
                                    <span className="letter-card-Item">
                       {/* <div className={`category-form text-center category-letter-${energy_label} text-white`}>
                            {energy_label}
                        </div>*/}
                    </span>
                                </div>
                            </section>
                            <CarouselCar
                                index={index}
                                setIndex={setIndex}
                                setLgShow={setLgShow}
                                media={media}
                                setMainCarFoto={setMainCarFoto}
                            />
                        </section>

                        <Button
                            className="shadow-none text-secondary d-block d-md-none mx-auto border-0 button-modal btn-link"
                            onClick={() => setLgShow(true)}
                        >
                            <u>Bekijk alle {media.length ? media.length : 0} foto's</u>
                        </Button>
                        <section className="col-12 col-md-5 text-secondary m-0">
                            <h2 className=" pb-4 car-details-title">
                                <b>{advertisement.title} </b> {/* {brand} {model} {type} {Math.ceil(horse_power )}PK {fuel}*/}
                            </h2>
                            <section className="col-12 p-0">
                                <ul className="list-unstyled row">
                                    <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                        <span className="d-inline-block col-6">Kenteken</span>
                                        <span className="d-inline-block col-6">{license_plate}</span>
                                    </li>
                                    <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                        <span className="d-inline-block col-6">Kilometerstand</span>
                                        <span className="d-inline-block col-6">{km_travelled || '...'} km</span>
                                    </li>
                                    <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                        <span className="d-inline-block col-6">Bouwjaar</span>
                                        <span className="d-inline-block col-6">{year_of_production || '...'}</span>
                                    </li>
                                    <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                        <span className="d-inline-block col-6">APK geldig tot</span>
                                        <span
                                            className="d-inline-block col-6">{transformMotValidUntil(mot_valid_until) || '...'}</span>
                                    </li>
                                    <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                        <span className="d-inline-block col-6">Brandstof</span>
                                        <span className="d-inline-block col-6">{fuel || '...'}</span>
                                    </li>
                                    <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                        <span className="d-inline-block col-6">Verbruik (l/100km)</span>
                                        <span className="d-inline-block col-6">{fuel_consumption_rate_l || '...'}</span>
                                    </li>
                                    <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                        <span className="d-inline-block col-6">Carrosserievorm</span>
                                        <span className="d-inline-block col-6">{type || '...'}</span>
                                    </li>
                                    <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                        <span className="d-inline-block col-6">Aantal deuren</span>
                                        <span className="d-inline-block col-6">{number_of_doors || '...'}</span>
                                    </li>
                                    <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                        <span className="d-inline-block col-6">Transmissie</span>
                                        <span className="d-inline-block col-6">{transmission || '...'}</span>
                                    </li>
                                    <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                        <span className="d-inline-block col-6">Geïmporteerd</span>
                                        <span className="d-inline-block col-6">{is_imported ? 'Ja' : 'Nee'}</span>
                                    </li>
                                    <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                        <span className="d-inline-block col-6">Onderhoudsboekje</span>
                                        <span className="d-inline-block col-6">
                      {has_maintenance_booklet ? 'Ja' : 'Nee'}
                    </span>
                                    </li>
                                    <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start align-items-center">
                                        <span className="d-inline-block col-6">Prijs</span>
                                        <h5 className="d-inline-block col-6 m-0 text-dark font-weight-bold">
                                            &euro; {formatPrice(price_excluding_vat) || '...'}
                                        </h5>
                                    </li>
                                </ul>
                            </section>
                            <Row className="col-12 m-0 p-0">
                                <section className="col-6 pl-0 ">
                                    <a
                                        href={'tel:' + phone}
                                        className="text-decoration-none text-white btn btn-success rounded-0 w-100"
                                    >
                                        Bel aanbieder
                                    </a>
                                </section>
                                <section className="col-6 pr-0">
                                    <a
                                        href={'mailto:' + email}
                                        className="text-decoration-none text-white  btn btn-success rounded-0 w-100"
                                    >
                                        E-mail aanbieder
                                    </a>
                                </section>
                                <section className="col-12 mt-3 mx-0 px-0">
                                    {FinancialLeaseButton(
                                        price_excluding_vat,
                                        <span className="text-decoration-none text-white btn btn-success rounded-0 w-100">
                                            Financieren?
                                        </span>
                                    )}
                                </section>
                            </Row>
                        </section>
                    </Row>
                    <Button
                        className="shadow-none text-secondary d-none d-md-block mx-auto border-0 button-modal btn-link"
                        onClick={() => setLgShow(true)}
                    >
                        <u>Bekijk alle {media.length ? media.length : 0} foto's</u>
                    </Button>
                    <ButtonToolbar>
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="modal-car-page">{brand}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <CarouselModalCar media={media} index={index} setIndex={setIndex}/>
                            </Modal.Body>
                        </Modal>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Modal
                            size="lg"
                            show={imageShow}
                            onHide={() => setImageShow(false)}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="carousel-car-page">{brand}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <img
                                    src={
                                        urlMainImgCar ||
                                        (media.length === 0
                                            ? 'https://cdn.riastatic.com/photosnew/auto/photo/Bugatti_Chiron__206758890f.jpg'
                                            : feature_image)
                                    }
                                    className="w-100"
                                    alt="car"
                                />
                            </Modal.Body>
                        </Modal>
                    </ButtonToolbar>
                    <AccordionCar
                        advertiser_id={advertiser_id}
                        hexon_uuid={hexon_uuid}
                        price_excluding_vat={price_excluding_vat}
                        model={model}
                        license_plate={license_plate}
                        is_margin={is_margin}
                        performance={performance}
                        color={color}
                        transmission={transmission}
                        type={type}
                        fuel={fuel}
                        number_of_doors={number_of_doors}
                        towing_weight_kg={towing_weight_kg}
                        year_of_production={year_of_production}
                        km_travelled={km_travelled}
                        number_of_seats={number_of_seats}
                        energy_label={energy_label}
                        fuel_consumption_rate_l={fuel_consumption_rate_l}
                        horse_power={horse_power}
                        engine_capacity_cc={engine_capacity_cc}
                        mot_valid_until={mot_valid_until}
                        is_imported={is_imported}
                        has_maintenance_booklet={has_maintenance_booklet}
                        options={options}
                        description={description}
                        name={name}
                        address={address}
                        phone={phone}
                        city={city}
                        zipcode={zipcode}
                        email={email}
                        bovag_warranty={bovag_warranty}
                        nap={nap}
                        brand_warranty={brand_warranty}
                        top_speed={top_speed}
                        apk={transformMotValidUntil(mot_valid_until)}
                        date_part_1={date_part_1}
                        road_tax={road_tax}
                        acceleration={acceleration}
                        amount_of_cylinders={amount_of_cylinders}
                        towing_weight_max={towing_weight_max}
                        towing_weight_unrestrained={towing_weight_unrestrained}
                        advertisement_id={advertisement_id}
                    />
                    <div className={'my-5'}>
                        {FinancialLeaseButton(
                            price_excluding_vat,
                            <span className="text-decoration-none text-white btn btn-success rounded-0 w-100 py-2">
                                Financieren?
                            </span>
                        )}
                    </div>

                </main>
                <aside className="d-none d-md-block col-md-2">
                    <Row>
                        <section className="col-6 col-md-12 p-3">
                            <Banner banner_id={"HOME_160x600_A"} width={160} height={600} banners={banners}/>
                        </section>
                        <section className="col-6 col-md-12 p-3">
                            <Banner banner_id={"HOME_160x600_B"} width={160} height={600} banners={banners}/>
                        </section>
                        {/*<section className="col-md-12 p-3">
                            <div className="container advertising-aside-home-page bg-secondary"></div>
                        </section>
                        <section className="col-md-12 p-3">
                            <div className="container advertising-aside-home-page bg-secondary"></div>
                        </section>
                        <section className="col-md-12 p-3">
                            <div className="container advertising-aside-home-page bg-secondary"></div>
                        </section>*/}
                    </Row>
                </aside>
            </section>
        </>
    );
};
