import React, {useEffect, useState} from 'react';
import {Navigation} from "../../layout/Navigation";
import {fetchContentForPage} from "../../util/RestQueries";

export const FaqPage = () => {
    const titles = ['Rijzuinig', 'Veelgestelde vragen'];
    const [html, setHtml] = useState("");

    useEffect(() => {
        const fetchData = async () =>
            setHtml(await fetchContentForPage("faq"))
        fetchData();
    }, []);
    return (
        <>
            <Navigation titles={titles}/>
            <div className="container wrapper">
                <div dangerouslySetInnerHTML={{__html: html}}/>
            </div>
        </>
    );
};
