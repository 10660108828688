import React, {useState} from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import bovag_garantie from "./../../../images/bovag_garantie.jpg";
import nap_garantie from "./../../../images/nap_garantie.jpg";
import huismerk_garantie from "./../../../images/huismerk_garantie.jpg";
import {useHistory} from "react-router-dom";
import {FormCar} from "./FormCar";
import {pushNewSearch} from "../../util/reactUtils";
import {formatPrice, formatWithSuffix, getPartAfter, getPartBefore, reverseYearMonthDate} from "../../util/domainUtils";
import {FinancialLeaseButton} from "../../util/AutoLease/AutoLease";

export const AccordionCar = (props: any) => {
    const history = useHistory();
    const {
        advertiser_id,
        advertisement_id,
        // hexon_uuid,
        price_excluding_vat,
        license_plate,
        is_margin,
        // performance,
        color,
        transmission,
        type,
        fuel,
        number_of_doors,
        // towing_weight_kg,
        year_of_production,
        km_travelled,
        number_of_seats,
        energy_label,
        fuel_consumption_rate_l,
        horse_power,
        engine_capacity_cc,
        // mot_valid_until,
        // is_imported,
        // has_maintenance_booklet,
        options,
        description,
        name,
        address,
        phone,
        city,
        zipcode,
        email,
        bovag_warranty,
        brand_warranty,
        nap,
        top_speed,
        date_part_1,
        road_tax,
        acceleration,
        amount_of_cylinders,
        towing_weight_max,
        towing_weight_unrestrained,
        apk,
    } = props;

    const [showMoreInfotmation, setShowMoreInfotmation] = useState(true);
    const [showCompanyInfotmation, setShowCompanyInfotmation] = useState(false);

    const toggleClassActive = (event: any) => event.target.classList.toggle('active');

    const getNapAdvertisers = () => {
        const query = {} as any;
        query.nap = "national_car_pass";
        pushNewSearch(history, query);

    };

    const showCarProviders = () => {
        const query = {} as any;
        query.advertiser_id = advertiser_id;
        pushNewSearch(history, query);
    }

    const handleClick = () => (setShowMoreInfotmation(!showMoreInfotmation));
    const handleCompanyClick = () => (setShowCompanyInfotmation(!showCompanyInfotmation));

    const splitZipCode = (zipcode: any) => {
        if (zipcode === undefined || zipcode === null)
            return ["-", "-", "-"];
        return zipcode.split(/(\d+)/);
    }

    const splitPhone = (value: any, index: number) => {
        if (value === undefined || value === null)
            return ["-", "-", "-", "-"]
        return `${value.substring(0, index)} ${value.substring(index)}`;
    }

    return (
        <>
            <Accordion defaultActiveKey="0" className="mt-2 mt-md-0">
                <Card className="border-0 bg">
                    <Accordion.Toggle
                        className="pl-0 bg-white"
                        onClick={event => toggleClassActive(event)} as={Card.Header}
                        eventKey="0"
                    >
                        Aanbieder
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body className="pl-0 bg-white row d-flex justify-content-between">
                            <div className="col-12 col-md-7">
                                <p className="m-0">{name}</p>
                                <p className="m-0">{address}</p>
                                <p className="m-0">{splitZipCode(zipcode)[1]} {splitZipCode(zipcode)[2]} {city}</p>
                                <p className="m-0">{splitPhone(phone, 3)}</p>
                                <p className="m-0">{email}</p>
                                <div className="col-12 p-0">
                                    <div className="d-inline-block cursor-pointer p-0"
                                         onClick={() => showCarProviders()}>
                                        <u className="cursor-pointer">
                                            Bekijk alle occasions van deze aanbieder
                                        </u>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 p-0 mt-3 mt-md-0">
                                <p className="pl-3">
                                    Deze auto wordt geleverd
                                </p>
                                <div className="col-4 col-md-12 mt-3 mt-md-0 d-flex justify-content-between">
                                    {
                                        nap ? <div className="col-5 p-1 border border-dark cursor-pointer"
                                                   onClick={() => getNapAdvertisers()}>
                                                <img src={nap_garantie} className="w-100" alt="nap garantie"/>
                                            </div> :
                                            null
                                    }
                                    {
                                        bovag_warranty ? <div className="col-5 p-1 border border-dark cursor-pointer"
                                                              onClick={() => getNapAdvertisers()}>
                                                <img src={bovag_garantie} className="w-100" alt="bovag garantie"/>
                                            </div> :
                                            null
                                    }
                                    {
                                        brand_warranty  ? <div className="col-5 p-1 border border-dark cursor-pointer"
                                                              onClick={() => getNapAdvertisers()}>
                                                <img src={huismerk_garantie} className="w-100" alt="huismerk garantie"/>
                                            </div> :
                                            null
                                    }
                                </div>
                            </div>
                            <section className="col-12 text-md-right">
                                <label
                                    className="label-form-homepage-extended"
                                >
                                    <input type="checkbox" onClick={handleCompanyClick}/>
                                    <p className="text-form-homepage-extended cursor-pointer">
                                        Bedrijfsinformatie
                                    </p>
                                </label>
                            </section>
                            <section className={`${showCompanyInfotmation ? 'd-block':  'd-none'} col-12 col-md-12 m-0`}>

                                    {getPartAfter(description,"= Bedrijfsinformatie =").split("\n")
                                        .map((descript: any, index: number) => {
                                        return <p  key={index}>{descript}</p>;
                                    })}

                            </section>

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <Accordion defaultActiveKey="1">
                <Card className="border-0">
                    <Accordion.Toggle
                        className="pl-0 bg-white"
                        onClick={event => toggleClassActive(event)}
                        as={Card.Header}
                        eventKey="1"
                    >
                        Algemeen
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body className="row pl-0 bg-white">
                            <section className="col-12 col-md-6 m-0">
                                <section className="col-12 p-0">
                                    <ul className="list-unstyled row">
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Prijs</span>
                                            <section className="font-weight-bold col-6 row m-0 pl-3">
                                                <span>
                                                    &euro; {formatPrice(price_excluding_vat)}{/* {!is_margin? "excl. BTW": "incl. BTW"}*/}
                                                </span>
                                                {FinancialLeaseButton(
                                                    price_excluding_vat,
                                                    <span className={"d-inline-block text-dark pl-3 p-0"}><u>Financieren?</u></span>
                                                )}
                                            </section>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Bouwjaar</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {year_of_production}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Kilometerstand</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {km_travelled}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Brandstof</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {fuel}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Transmissie</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {transmission}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">APK</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {apk || '-'}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">BTW auto</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {!is_margin ? 'Ja' : 'Nee'}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Kenteken</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {license_plate}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Datum deel 1</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {date_part_1!=null? reverseYearMonthDate(date_part_1) : '-'}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Belasting (kwartaal)</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {road_tax || '-'}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Topsnelheid</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {formatWithSuffix(top_speed, ' km/u', '-')}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Acceleratie</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {formatWithSuffix(acceleration, ' sec. 100 km/h', '-')}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Motorvermogen</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {horse_power ? `${Math.ceil(horse_power )}PK (${Math.ceil(horse_power/1.362)}kW)` : '-'}
                                            </span>
                                        </li>
                                    </ul>
                                </section>
                            </section>
                            <section className="col-12 col-md-6 m-0">
                                <section className="col-12 p-0">
                                    <ul className="list-unstyled row">
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Aantal deuren</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {number_of_doors}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span
                                                className="text-secondary d-inline-block col-6">Aantal zitplaatsen</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {number_of_seats}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span
                                                className="text-secondary d-inline-block col-6">Aantal cilinders</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {amount_of_cylinders || '-'}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Cilinderinhoud</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {engine_capacity_cc || '-'}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Energielabel</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {energy_label}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Carrosserie</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {type}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span
                                                className="text-secondary d-inline-block col-6">Kleur</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {color}
                                            </span>
                                        </li>
                                       {/* <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Ledig gewicht</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {formatWithSuffix(towing_weight_kg, ' kg', '-')}
                                            </span>
                                        </li>*/}
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span className="text-secondary d-inline-block col-6">Trekgewicht max</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {formatWithSuffix(towing_weight_max, ' kg', '-')}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span
                                                className="text-secondary d-inline-block col-6">Trekgewicht ongeremd</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {formatWithSuffix(towing_weight_unrestrained, ' kg', '-')}
                                            </span>
                                        </li>
                                        <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span
                                                className="text-secondary d-inline-block col-6">Verbruik</span>
                                            <span className="font-weight-bold d-inline-block col-6">
                                                {formatWithSuffix(fuel_consumption_rate_l,' l per 100 km', '-')}
                                            </span>
                                        </li>
                                       {/* <li className="mb-2 mb-md-0 mb-lg-2 p-0 col-12 d-flex align-items-start">
                                            <span
                                                className="text-secondary d-inline-block col-6">Introductiejaar</span>
                                            <span
                                                className="font-weight-bold d-inline-block col-6 font-weight-bold text-dark">
                                                {year_of_production || '-'}
                                            </span>
                                        </li>*/}
                                    </ul>
                                </section>
                            </section>
                            <section className="col-12 text-md-right">
                                <label
                                    className="label-form-homepage-extended"
                                >
                                    <input type="checkbox" onClick={handleClick}/>
                                    <p className="text-form-homepage-extended cursor-pointer">
                                        Meer modelinformatie
                                    </p>
                                </label>
                            </section>
                            <section className={`${showMoreInfotmation ? 'd-block': 'd-none' } col-12 m-0`}>
                                <ul className="list-info">
                                    {options.map((option: any, index: number) => <li key={index}
                                                                                     className="list-info-item">
                                        <span>&#x2714;</span>{option.name}</li>)}
                                </ul>
                            </section>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Accordion defaultActiveKey="0">
                <Card className="border-0">
                    <Accordion.Toggle
                        className="active pl-0 bg-white"
                        onClick={event => toggleClassActive(event)} as={Card.Header}
                        eventKey="3"
                    >
                        Verzekeren - Bereken uw verzekeringspremie
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body className="p-2 bg-white">Leeg</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <Accordion defaultActiveKey="0">
                <Card className="border-0">
                    <Accordion.Toggle
                        className="active pl-0 bg-white"
                        onClick={event => toggleClassActive(event)} as={Card.Header}
                        eventKey="4"
                    >
                        Extra informatie
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body className="p-2 bg-white">
                            {getPartBefore(description,"= Bedrijfsinformatie =").split("\n").map((descript: any, index: number) => {
                                return <p key={index}>{descript}</p>;
                            })}
{/*                            <p className="m-0">Transmissie: {transmission}</p>
                            <p className="m-0">Modeljaar: {year_of_production}</p>
                            <p className="m-0">Wielbasis: 231 cm</p>
                            <p className="m-0">Bekleding: Stof </p>
                            <p className="m-0">Interieurkleur Decker blauw</p>
                            <p className="m-0">Motorrijtuigenbelasting: gees</p>*/}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <Accordion defaultActiveKey="0">
                <Card className="border-0">
                    <Accordion.Toggle
                        className="active pl-0 bg-white"
                        onClick={event => toggleClassActive(event)} as={Card.Header}
                        eventKey="5"
                    >
                        Contact opnemen met aanbieder
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="5">
                        <FormCar advertisement_id={advertisement_id}/>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    )
};
