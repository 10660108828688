import React, {useState} from 'react';
import {QueryDto} from "../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../apis";
import {withUniqueAdded, withUniqueRemoved} from "../../../util/reactUtils";


export const EnergyRatingContainer = (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;
    const [label, _setLabel] = useState(queryDto.label);


    const handleChange = (event: any, label: string) => {
        if (event.target.checked) {
            queryDto.label = withUniqueAdded(queryDto.label, label);
        } else {
            queryDto.label = withUniqueRemoved(queryDto.label, label) as any;
        }
        _setLabel(queryDto.label);
        saveQuery('label', queryDto.label);

    }
    const isChecked = (label: string): boolean => {
        const labels = queryDto.label || [];
        return labels.some(lab => lab === label);
    }
    return (
        <>
            <div className="pl-0 col-5 col-sm-4 col-md-4 col-lg-3 text-left d-flex align-items-center">
                <span className="font-weight-bold">Energielabel: </span>
            </div>

            <div
                className="d-flex col-1 col-sm-1 col-md-1 col-lg-1 p-0 justify-content-start justify-content-md-start justify-content-lg-start">




                <div className="form-check-inline">
                    <label className="form-check-label cursor-pointer">
                        <input type="checkbox" name="A" onChange={(ev: any) => handleChange(ev, "A")}
                               checked={isChecked("A")}/>
                        <div className="category-form text-center category-letter-A text-white">
                            A
                        </div>
                    </label>
                </div>

                <div style={{display: "none"}}>
                    <div className="form-check-inline">
                        <label className="form-check-label cursor-pointer">
                            <input type="checkbox" name="B" onChange={(ev: any) => handleChange(ev, "B")}
                                   checked={isChecked("B")}/>
                            <div className="category-form text-center category-letter-B text-white">
                                B
                            </div>
                        </label>
                    </div>
                    <div className="form-check-inline">
                        <label className="form-check-label cursor-pointer">
                            <input type="checkbox" name="C" onChange={(ev: any) => handleChange(ev, "C")}
                                   checked={isChecked("C")}/>
                            <div className="category-form text-center category-letter-C text-white">
                                C
                            </div>
                        </label>
                    </div>
                    <div className="form-check-inline">
                        <label className="form-check-label cursor-pointer">
                            <input type="checkbox" name="D" onChange={(ev: any) => handleChange(ev, "D")}
                                   checked={isChecked("D")}/>
                            <div className="category-form text-center category-letter-D text-white">
                                D
                            </div>
                        </label>
                    </div>
                    <div className="form-check-inline">
                        <label className="form-check-label cursor-pointer">
                            <input type="checkbox" name="E" onChange={(ev: any) => handleChange(ev, "E")}
                                   checked={isChecked("E")}/>
                            <div className="category-form text-center category-letter-E text-white">
                                E
                            </div>
                        </label>
                        {/* <label className="form-check-label cursor-pointer">
                        <input type="checkbox" name="F" onChange={(ev: any) => handleChange(ev, "F")}
                               checked={isChecked("F")}/>
                        <div className="category-form text-center category-letter-F text-white">
                            F
                        </div>
                    </label>
                    <label className="form-check-label cursor-pointer">
                        <input type="checkbox" name="G" onChange={(ev: any) => handleChange(ev, "G")}
                               checked={isChecked("G")}/>
                        <div className="category-form text-center category-letter-G text-white">
                            G
                        </div>
                    </label>*/}
                    </div>
                </div>


            </div>

        </>
    )
}

