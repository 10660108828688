import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { DropdownFormInput } from "./dropdowns/DropdownFormInput";
import { itemsType } from "../../../Constants";
import {QueryDto} from "../../../util/RestQueries";
import {SaveQueryDefault} from "../../../../apis";


export const BodyWorkContainer= (props: {
    queryDto: QueryDto, saveQuery: SaveQueryDefault
} & any) => {
    const queryDto: QueryDto = props.queryDto;
    const saveQuery: SaveQueryDefault = props.saveQuery;

    const [bodyWorkPlaceholder, _setBodyWorkPlaceholder] = useState(queryDto.type/*getSessionStorageProperty('bodyWorkPlaceholder') */|| 'Carrosserie...');
    const [filteredBodyWorkItems, setFilteredBodyWorkItems] = useState(itemsType);
    const [inputBodyWorkValue, setInputBodyWorkValue] = useState('');


    const setBodyWorkPlaceholder = (value: any) => {
        queryDto.type=value;
        saveQuery('type', value);
        _setBodyWorkPlaceholder(value);
    }


    if ((props.clearForm || queryDto.type===undefined) && bodyWorkPlaceholder !== 'Carrosserie...') {
        _setBodyWorkPlaceholder('Carrosserie...');
    }

    return (
        <Form.Group className="col-12 col-md-6 col-lg-3 p-1" controlId="dropdownBodyWork">
            <DropdownFormInput
                filteredItems={filteredBodyWorkItems}
                getDropDownItems={props.getDropDownItems}
                placeholder={bodyWorkPlaceholder}
                inputValue={inputBodyWorkValue}
                value={'bodyWork'}
                countInputs={() => false}
                setPlaceholder={setBodyWorkPlaceholder}
                setInputValue={setInputBodyWorkValue}
                setFilteredItems={setFilteredBodyWorkItems}
                dropDownItems={itemsType}
                setClearForm={props.setClearForm}
                checkVisibleForm={props.checkVisibleForm}
            />
        </Form.Group>
    )
}